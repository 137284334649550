import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import history from './lib/history'
import { session, subscription } from './lib/api';

import "./App.scss";

import Header from './sections/Header';
import CallToSubscribe from "./sections/CallToSubscribe";
import Footer from './sections/Footer';
import BackToTop from "./sections/BackToTop";
import Privacy from "./sections/Privacy";
import Terms from "./sections/Terms";

import NotFound from "./sections/error/NotFound";

import Home from './sections/home/Home';
import SignUp from './sections/session/SignUp';
import SignIn from "./sections/session/SignIn";
import SignOut from "./sections/session/SignOut";

import ClientList from "./sections/client/ClientList";
import ClientAdd from "./sections/client/ClientAdd";

import RecipeList from "./sections/recipe/RecipeList";
import RecipeAdd from "./sections/recipe/RecipeAdd";
import RecipeDetails from "./sections/recipe/RecipeDetails";

import FoodList from "./sections/food/FoodList";
import FoodDetails from "./sections/food/FoodDetails";

import AllergenList from "./sections/allergen/AllergenList";

import Config from "./sections/config/Config";
import ConfigNutritionist from "./sections/config/ConfigNutritionist";

import CheckoutSuccess from "./sections/checkout/CheckoutSuccess";
import CheckoutError from "./sections/checkout/CheckoutError";
import ConfigSubscribe from "./sections/config/ConfigSubscribe";

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            subscription: null,
            loading: true
        };

        this.onSignIn = this.onSignIn.bind(this);
        this.onSignOut = this.onSignOut.bind(this);
    }

    componentDidMount() {
        Promise.all([ session.get(), subscription.get() ])
            .then(([ user, subscription ]) => {
                this.setState({
                    user: user,
                    subscription: subscription,
                    loading: false
                });
            })
            .catch(error => {
                this.setState({
                    user: null,
                    subscription: null,
                    loading: false
                });
            });
    }

    async onSignIn() {
        Promise.all([ session.get(), subscription.get() ])
            .then(([ user, subscription ]) => {
                this.setState({
                    user: user,
                    subscription: subscription,
                    loading: false
                }, () => history.push("/after-sign-in"));
            })
            .catch(error => {
                this.setState({
                    user: null,
                    subscription: null,
                    loading: false
                });
            });
    }

    onSignOut() {
        this.setState({
            user: null,
            subscription: null
        }, () => history.push("/after-sign-out"));
    }

    render() {
        const PublicRoute = ({ component: Component, ...rest }) => {
            return (
                <Route
                    {...rest}
                    render={props =>
                        <Component user={this.state.user} subscription={this.state.subscription} onSignIn={this.onSignIn} onSignOut={this.onSignOut} {...props} />
                    }
                />
            );
        }

        // https://medium.com/@melih193/react-with-react-router-5-9bdc9d427bfd
        const PrivateRoute = ({ component: Component, ...rest }) => {
            return (
                <Route
                    {...rest}
                    render={props =>
                        this.state.user !== null ? (
                            <Component user={this.state.user} subscription={this.state.subscription} onSignIn={this.onSignIn} onSignOut={this.onSignOut} {...props} />
                        ) : (
                            <Redirect to={{ pathname: "/entrar" }} />
                        )
                    }
                />
            );
        }

        // TODO
        if (this.state.loading) {
            return (
                <h1>Loading...</h1>
            );
        }

        return (
            <Router history={history}>
                <Header user={this.state.user} subscription={this.state.subscription} />
                <CallToSubscribe user={this.state.user} subscription={this.state.subscription} />
                <Switch>
                    <PublicRoute exact path="/" component={Home} />
                    <PublicRoute exact path="/registar"  component={SignUp} />
                    <PublicRoute exact path="/entrar" component={SignIn} />
                    <PublicRoute exact path="/sair" component={SignOut} />

                    <PrivateRoute exact path="/clientes" component={ClientList} />
                    <PrivateRoute exact path="/clientes/adicionar" component={ClientAdd} />
                    <PrivateRoute exact path="/clientes/:id/editar" component={ClientAdd} />

                    <PrivateRoute exact path="/receitas" component={RecipeList} />
                    <PrivateRoute exact path="/receitas/adicionar" component={RecipeAdd} />
                    <PrivateRoute exact path="/receitas/:id/editar" component={RecipeAdd} />
                    <PrivateRoute exact path="/receitas/:id" component={RecipeDetails} />

                    <PrivateRoute exact path="/alimentos" component={FoodList} />
                    <PrivateRoute exact path="/alimentos/:id" component={FoodDetails} />

                    <PrivateRoute exact path="/alergenios" component={AllergenList} />

                    <PrivateRoute exact path="/config" component={Config} />
                    <PrivateRoute exact path="/config/nutritionista" component={ConfigNutritionist} />

                    <PrivateRoute exact path="/config/subscrever" component={ConfigSubscribe} />

                    <PrivateRoute exact path="/checkout/sucesso" component={CheckoutSuccess} />
                    <PrivateRoute exact path="/checkout/erro" component={CheckoutError} />

                    <Redirect from="/after-sign-in" to="/receitas" />
                    <Redirect from="/after-sign-out" to="/" />

                    <PublicRoute exact path="/privacidade" component={Privacy} />
                    <PublicRoute exact path="/termos" component={Terms} />

                    <PublicRoute component={NotFound} />
                </Switch>
                <Footer />
                <BackToTop />
            </Router>
        );
    }
}

export default App;
