import React, { Component } from 'react';
import { file } from '../../lib/api';

class FileUpload extends Component {

    constructor(props) {
        super(props);

        this.defaultState = {
            id: null,
            name: null
        };

        this.state = this.defaultState;

        this.onFileChange = this.onFileChange.bind(this);
        this.onFileDelete = this.onFileDelete.bind(this);
    }

    componentDidMount() {
        if (this.props.value) {
            file.get(this.props.value)
                .then(file => this.setState({
                    id: file.id,
                    name: file.name
                }))
                .catch(error => this.setState(this.defaultState));
        }
    }

    onFileChange(event) {
        const formData = new FormData();
        formData.append('file', event.target.files[0]);

        file.add(formData)
            .then(file => {
                this.props.onChange(file.id);
                this.setState({
                    id: file.id,
                    name: file.name
                });
            })
            .catch(error => {
                this.setState(this.defaultState);
                console.log(error);
            });
    }

    onFileDelete() {
        this.input.value = '';
        this.props.onChange(null);
        this.setState(this.defaultState);
    }

    render() {
        return (
            <div className={`file ${this.props.value ? "has-name" : ''}`} style={{ alignItems: 'flex-end' }}>
                <label className="file-label">
                    <input ref={(ref) => this.input = ref} className="file-input" name="files" type="file" accept={this.props.accept} onChange={this.onFileChange} />
                    <span className="file-cta">
                        <span className="file-icon">
                            <i className="fas fa-upload"></i>
                        </span>
                        <span className="file-label">
                            Escolha um ficheiro…
                        </span>
                    </span>
                    { this.props.value &&
                    <span className="file-name">
                        {this.state.name}
                    </span>
                    }
                </label>
                { this.props.value &&
                <button className="button is-light is-danger ml-1" onClick={this.onFileDelete}>
                    <span className="icon is-small">
                        <i className="far fa-trash-alt"></i>
                    </span>
                </button>
                }
            </div>
        );
    }
}

export default FileUpload;
