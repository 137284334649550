import React, { Component } from 'react';
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { NavHashLink } from 'react-router-hash-link';

import logo from '../img/nutrit.png';

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            open: false
        };

        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.renderNavLink = this.renderNavLink.bind(this);
    }

    onToggleMenu() {
        this.setState(prevState => {
            return {
                open: !prevState.open
            };
        });
    }

    renderNavLink(path, title) {
        return (
            <NavHashLink
                to={path}
                className={ "navbar-item ml-1" + (this.props.location.pathname === path ? " is-active" : "") }
                onClick={() => this.setState({ open: false })}
            >{title}</NavHashLink>
        );
    }

    renderDropdownNavLink(path, title) {
        return (
            <NavHashLink
                to={path}
                className="dropdown-item"
                onClick={() => this.setState({ open: false })}
            >{title}</NavHashLink>
        );
    }

    render() {
        const menuToggle = this.state.open ? " is-active" : "";

        return (
            <nav className="navbar is-spaced is-white has-shadow" role="navigation" aria-label="main navigation">
                <div className="container">
                    <div className="navbar-brand">
                        <NavLink to='/' className="navbar-item">
                            <img src={logo} alt=""/>
                        </NavLink>
                        <a role="button" className={"navbar-burger burger" + menuToggle} aria-label="menu" aria-expanded="false" data-target="navbarBasicExample" onClick={this.onToggleMenu}>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </a>
                    </div>
                    <div id="navbarBasicExample" className={"navbar-menu" + menuToggle}>
                        {!this.props.user &&
                        <div className="navbar-end">
                            { this.renderNavLink("/", "Início") }
                            { this.renderNavLink("/#funcionalidades", "Funcionalidades") }
                            { this.renderNavLink("/#precario", "Preçário") }
                            { this.renderNavLink("/registar", "Criar conta") }
                            { this.renderNavLink("/entrar", "Entrar") }
                        </div>
                        }
                        {this.props.user &&
                        <div className="navbar-end">
                            { this.renderNavLink("/clientes", "Clientes") }
                            { this.renderNavLink("/receitas", "Receitas") }
                            { this.renderNavLink("/alimentos", "Alimentos") }
                            { this.renderNavLink("/alergenios", "Alergénios") }

                            <div className="navbar-item has-dropdown is-hoverable ml-1">
                                <a className="navbar-link">
                                    <span className="icon is-medium mr-1">
                                        <i className="fas fa-lg fa-user-circle"></i>
                                    </span>
                                    <span>{this.props.user.name}</span>
                                </a>
                                <div className="navbar-dropdown is-right">
                                    { this.renderDropdownNavLink("/config", "Configurações") }
                                    { this.renderDropdownNavLink("/sair", "Sair") }
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </nav>
        );
    }
}

export default withRouter(Header);
