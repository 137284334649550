import React, { Component } from 'react';
import { nutritionist } from '../../lib/api';
import Page from '../common/Page';
import Loading from '../common/Loading';
import Form, { Input, TextArea } from '../common/Form';

class ConfigNutritionist extends Component {

    constructor(props) {
        super(props);

        this.defaultState = {
            form: {
                name: "",
                description: ""
            },
            error: false,
            loading: true
        };

        this.state = this.defaultState;

        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onFormCancel = this.onFormCancel.bind(this);
    }

    componentDidMount() {
        nutritionist.get()
            .then(nutritionist => this.setState({
                form: {
                    name:        nutritionist.name,
                    description: nutritionist.description
                },
                loading: false
            }))
            .catch(error => this.setState({
                error: true,
                loading: true
            }));
    }

    onChange(name, value) {
        this.setState(prevState => {
            return {
                form: {
                    ...prevState.form,
                    [name]: value
                },
                error: false
            };
        });
    }

    onFormSubmit(event) {
        event.preventDefault();

        nutritionist.edit(this.state.form)
            .then(nutritionist => {
                this.props.history.push("/config");
            })
            .catch(error => {
                this.setState({
                    error: true
                });
            });
    }

    onFormCancel(event) {
        this.props.history.push("/config");
    }

    render() {
        if (this.state.loading) {
            return (
                <Page title="Editar Nutricionista">
                    <Loading />
                </Page>
            );
        }

        return (
            <Page title="Editar Nutricionista">
                <Form submitLabel="Editar" cancelLabel="Cancelar" onSubmit={this.onFormSubmit} onCancel={this.onFormCancel}>
                    <Input
                        label="Nome"
                        placeholder="Nome"
                        value={this.state.form.name}
                        onChange={(value) => this.onChange("name", value) }
                        style={{ maxWidth: '400px' }}
                        error={this.state.error}
                    />
                    <TextArea
                        label="Descrição"
                        placeholder="Descrição"
                        rows="3"
                        value={this.state.form.description}
                        onChange={(value) => this.onChange("description", value) }
                        style={{ maxWidth: '400px' }}
                        error={this.state.error}
                    />
                </Form>
            </Page>
        );
    }
}

export default ConfigNutritionist;
