import React, { Component } from 'react';
import { allergen } from '../../lib/api';
import Loading from '../common/Loading';
import Page from '../common/Page';
import PageTable from '../common/PageTable';

class AllergenList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            allergens: null,
            loading: true
        };

        this.onRowClick = this.onRowClick.bind(this);
    }

    componentDidMount() {
        allergen.list()
            .then(allergens => this.setState({
                allergens: allergens,
                loading: false
            }))
            .catch(error => this.setState({
                allergens: null,
                loading: true
            }));
    }

    onRowClick(id) {
        this.props.history.push(`/alergenios/${id}`);
    }

    render() {
        if (this.state.loading) {
            return (
                <Page title="Alergénios">
                    <Loading />
                </Page>
            );
        }

        // const numberFormatter = (value, decimals, unit) => {
        //     if (value) {
        //         return `${value.toFixed(decimals)} ${unit}`;
        //     } else {
        //         return `${(0).toFixed(decimals)} ${unit}`;
        //     }
        // }

        // const kcalFormatter = (value) => numberFormatter(value, 0, "kcal");
        // const gFormatter = (value) => numberFormatter(value, 1, "g");
        // const mgFormatter = (value) => numberFormatter(value, 0, "mg");

        return (
            <PageTable
                title="Alergénios"
                columns={[
                    { key: "name", title: "Nome", abbreviation: null, searchable: true, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-left", rowClassName: "is-nowrap ellipsis" }
                ]}
                rows={this.state.allergens}
                // onRowClick={this.onRowClick}
                orderBy="name"
                orderAscending={true}
            />
        );
    }
}

export default AllergenList;
