import React, { Component } from 'react';

import "./Loading.scss";

class Loading extends Component {

    render() {
        return (
            <div className="loading">
                <div className="lds-ripple"><div></div><div></div></div>
                <span>Por favor aguarde...</span>
            </div>
        );
    }
}

export default Loading;
