import React, { Component } from 'react';
import Chart from 'react-chartjs-2';
import 'chartjs-plugin-piechart-outlabels';
import 'chartjs-plugin-datalabels';

class PieChart extends Component {

    render() {
        const data = {
            labels: this.props.values.map(v => v.name),
            datasets: [{
                data: this.props.values.map(v => v.value),
                formatter: this.props.values.map(v => v.formatter),
                backgroundColor: this.props.colors
            }]
        };

        const options = {
            legend: {
                display: false,
                position: 'right'
            },
            tooltips: {
                callbacks: {
                    label: function(tooltipItem, data) {
                        const name = data.labels[tooltipItem.index];
                        const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                        const formatter = data.datasets[tooltipItem.datasetIndex].formatter[tooltipItem.index];
                        return `${name}: ${formatter(value)}`;
                    }
                }
            },
            zoomOutPercentage: 25,
            plugins: {
                outlabels: {
                    text: (data) => data.percent > .05 ? '%l' : null,
                    color: 'white',
                    stretch: 20,
                    font: {
                        resizable: true,
                        minSize: 11,
                        maxSize: 11
                    }
                },
                datalabels: {
                    formatter: (value, ctx) => {
                        const sum = ctx.dataset.data.reduce((a, b) => a + b, 0);
                        const percentage = Math.round((value / sum) * 100);

                        if (percentage >= 5) {
                            return percentage + '%';
                        } else {
                            return null;
                        }
                    },
                    color: '#ffffff',
                }
            }
        }

        return (
            <Chart type="outlabeledDoughnut" data={data} options={options} width={100} height={70} />
        );
    }
}

export default PieChart;
