import React, { Component } from 'react';
import { Link } from "react-router-dom";

import illustration from '../../img/undraw_empty_xct9.svg';

class NotFound extends Component {

    render() {
        return (
            <section id="registar" className="section is-medium is-fullscreen">
                <div className="columns is-vcentered">
                    <div className="column is-5 is-offset-1 has-text-centered is-hidden-mobile">
                        <img className="illustration" src={illustration} alt=""/>
                    </div>
                    <div className="column is-offset-1">
                        <h1 className="title has-text-primary">Esta página não existe</h1>
                        <div className="buttons">
                            <Link to='/' className="button is-primary">Ir para a página principal</Link>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default NotFound;
