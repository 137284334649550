import React, { Component } from 'react';
import { food } from '../../lib/api';
import Loading from '../common/Loading';
import Page from '../common/Page';
import Table from '../common/Table';

class FoodDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id,
            data: null,
            loading: true
        };
    }

    componentDidMount() {
        food.get(this.state.id)
            .then(json => {
                this.setState({
                    data: json,
                    loading: false
                });
            })
            .catch(error => this.setState({
                data: null
            }));
    }

    render() {
        if (this.state.loading) {
            return (
                <Page>
                    <Loading />
                </Page>
            );
        }

        const numberFormatter = (value, decimals, unit) => {
            if (value) {
                return `${value.toFixed(decimals)} ${unit}`;
            } else {
                return `${(0).toFixed(decimals)} ${unit}`;
            }
        }

        const kcalFormatter = (value) => numberFormatter(value, 0, "kcal");
        const kjFormatter = (value) => numberFormatter(value, 0, "kJ");
        const gFormatter = (value) => numberFormatter(value, 2, "g");
        const mgFormatter = (value) => numberFormatter(value * 1000, 2, "mg");
        const µgFormatter = (value) => numberFormatter(value * 1000 * 1000, 2, "µg");

        const dynamicFormatter = (value, row) => row.formatter(value);

        const energia = [
            { id: "energia_kj",   name: "Energia", value: this.state.data["energia_kj"],   formatter: kjFormatter },
            { id: "energia_kcal", name: "Energia", value: this.state.data["energia_kcal"], formatter: kcalFormatter }
        ];

        const composicao = [
            { id: "lipidos_g",             name: "Lípidos",             value: this.state.data["lipidos_g"],              formatter: gFormatter },
            { id: "hidratos_de_carbono_g", name: "Hidratos de Carbono", value: this.state.data["hidratos_de_carbono_g"],  formatter: gFormatter },
            { id: "fibra_g",               name: "Fibra",               value: this.state.data["fibra_g"],                formatter: gFormatter },
            { id: "proteinas_g",           name: "Proteínas",           value: this.state.data["proteinas_g"],            formatter: gFormatter },
            { id: "sal_g",                 name: "Sal",                 value: this.state.data["sal_g"],                  formatter: gFormatter },
            { id: "alcool_g",              name: "Álcool",              value: this.state.data["alcool_g"],               formatter: gFormatter },
            { id: "agua_g",                name: "Água",                value: this.state.data["agua_g"],                 formatter: gFormatter },
            { id: "acidos_organicos_g",    name: "Ácidos Orgânicos",    value: this.state.data["acidos_organicos_g"],     formatter: gFormatter },
            { id: "colesterol_mg",         name: "Colesterol",          value: this.state.data["colesterol_mg"] / 1000.0, formatter: mgFormatter }
        ];

        const lipidos = [
            { id: "acidos_gordos_saturados_g",       name: "Ácidos gordos saturados",       value: this.state.data["acidos_gordos_saturados_g"],       formatter: gFormatter },
            { id: "acidos_gordos_monoinsaturados_g", name: "Ácidos gordos monoinsaturados", value: this.state.data["acidos_gordos_monoinsaturados_g"], formatter: gFormatter },
            { id: "acidos_gordos_polinsaturados_g",  name: "Ácidos gordos polinsaturados",  value: this.state.data["acidos_gordos_polinsaturados_g"],  formatter: gFormatter },
            { id: "acido_linoleico_g",               name: "Ácido linoleico",               value: this.state.data["acido_linoleico_g"],               formatter: gFormatter },
            { id: "acidos_gordos_trans_g",           name: "Ácidos gordos trans",           value: this.state.data["acidos_gordos_trans_g"],           formatter: gFormatter }
        ];

        // TODO sacarose e lactose?
        const hidratos = [
            { id: "acucares_g",        name: "Açúcares",         value: this.state.data["acucares_g"],        formatter: gFormatter },
            { id: "oligossacaridos_g", name: "Oligossacaáridos", value: this.state.data["oligossacaridos_g"], formatter: gFormatter },
            { id: "amido_g",           name: "Amido",            value: this.state.data["amido_g"],           formatter: gFormatter }
        ];

        // energia_kcal,energia_kj,lipidos_g,
        //   acidos_gordos_saturados_g,acidos_gordos_monoinsaturados_g,acidos_gordos_polinsaturados_g,acido_linoleico_g,acidos_gordos_trans_g,
        // hidratos_de_carbono_g,
        //   acucares_g,oligossacaridos_g,amido_g,
        // fibra_g,proteinas_g,sal_g,alcool_g,agua_g,acidos_organicos_g,colesterol_mg,
        
        const vitaminas = [
            { id: "vitamina_a_µg",              name: "Vitamina A",              value: this.state.data["vitamina_a_µg"] / 1000.0 / 1000.0,     formatter: µgFormatter },
            { id: "caroteno_µg",                name: "Caroteno",                value: this.state.data["caroteno_µg"] / 1000.0 / 1000.0,       formatter: µgFormatter },
            { id: "vitamina_d_µg",              name: "Vitamina D",              value: this.state.data["vitamina_d_µg"] / 1000.0 / 1000.0,     formatter: µgFormatter },
            { id: "alfa_tocoferol_mg",          name: "Alfa-tocoferol",          value: this.state.data["alfa_tocoferol_mg"] / 1000.0,          formatter: mgFormatter },
            { id: "tiamina_mg",                 name: "Tiamina",                 value: this.state.data["tiamina_mg"] / 1000.0,                 formatter: mgFormatter },
            { id: "riboflavina_mg",             name: "Riboflavina",             value: this.state.data["riboflavina_mg"] / 1000.0,             formatter: mgFormatter },
            { id: "niacina_mg",                 name: "Niacina",                 value: this.state.data["niacina_mg"] / 1000.0,                 formatter: mgFormatter },
            { id: "equivalentes_de_niacina_mg", name: "Equivalentes de niacina", value: this.state.data["equivalentes_de_niacina_mg"] / 1000.0, formatter: mgFormatter },
            { id: "triptofano_60_mg",           name: "Triptofano/60",           value: this.state.data["triptofano_60_mg"] / 1000.0,           formatter: mgFormatter },
            { id: "vitamina_b6_mg",             name: "Vitamina B6",             value: this.state.data["vitamina_b6_mg"] / 1000.0,             formatter: mgFormatter },
            { id: "vitamina_b12_µg",            name: "Vitamina B12",            value: this.state.data["vitamina_b12_µg"] / 1000.0 / 1000.0,   formatter: µgFormatter },
            { id: "vitamina_c_mg",              name: "Vitamina C",              value: this.state.data["vitamina_c_mg"] / 1000.0,              formatter: mgFormatter },
            { id: "folatos_µg",                 name: "Folatos",                 value: this.state.data["folatos_µg"] / 1000.0 / 1000.0,        formatter: µgFormatter }
        ];

        const minerais = [
            { id: "cinza_g",     name: "Cinza",    value: this.state.data["cinza_g"],              formatter: gFormatter },
            { id: "sodio_mg",    name: "Sódio",    value: this.state.data["sodio_mg"] / 1000.0,    formatter: mgFormatter },
            { id: "potassio_mg", name: "Potássio", value: this.state.data["potassio_mg"] / 1000.0, formatter: mgFormatter },
            { id: "calcio_mg",   name: "Cálcio",   value: this.state.data["calcio_mg"] / 1000.0,   formatter: mgFormatter },
            { id: "fosforo_mg",  name: "Fósforo",  value: this.state.data["fosforo_mg"] / 1000.0,  formatter: mgFormatter },
            { id: "magnesio_mg", name: "Magnésio", value: this.state.data["magnesio_mg"] / 1000.0, formatter: mgFormatter },
            { id: "ferro_mg",    name: "Ferro",    value: this.state.data["ferro_mg"] / 1000.0,    formatter: mgFormatter },
            { id: "zinco_mg",    name: "Zinco",    value: this.state.data["zinco_mg"] / 1000.0,    formatter: mgFormatter }
        ];

        return (
            <Page title={this.state.data.name}>
                <div className="columns">
                    <div className="column">
                        <Table
                            title="Energia"
                            columns={[
                                { key: "name",  title: "Nome",  sortable: false, headerClassName: "is-nowrap is-unselectable",                rowClassName: "is-nowrap" },
                                { key: "value", title: "Valor", sortable: false, headerClassName: "is-nowrap is-unselectable has-text-right", rowClassName: "is-nowrap has-text-right", formatter: dynamicFormatter },
                            ]}
                            rows={energia}
                            orderBy="value"
                            orderAscending={false}
                        />
                    </div>
                    <div className="column">
                        <Table
                            title="Composição"
                            columns={[
                                { key: "name",  title: "Nome",  sortable: true, headerClassName: "is-nowrap is-unselectable",                rowClassName: "is-nowrap" },
                                { key: "value", title: "Valor", sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right", rowClassName: "is-nowrap has-text-right", formatter: dynamicFormatter },
                            ]}
                            rows={composicao}
                            orderBy="value"
                            orderAscending={false}
                        />
                    </div>
                    <div className="column">
                        <Table
                            title="Lípidos"
                            columns={[
                                { key: "name",  title: "Nome",  sortable: true, headerClassName: "is-nowrap is-unselectable",                rowClassName: "is-nowrap" },
                                { key: "value", title: "Valor", sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right", rowClassName: "is-nowrap has-text-right", formatter: dynamicFormatter },
                            ]}
                            rows={lipidos}
                            orderBy="value"
                            orderAscending={false}
                        />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <Table
                            title="Hidratos de Carbono"
                            columns={[
                                { key: "name",  title: "Nome",  sortable: true, headerClassName: "is-nowrap is-unselectable",                rowClassName: "is-nowrap" },
                                { key: "value", title: "Valor", sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right", rowClassName: "is-nowrap has-text-right", formatter: dynamicFormatter },
                            ]}
                            rows={hidratos}
                            orderBy="value"
                            orderAscending={false}
                        />
                    </div>
                    <div className="column">
                        <Table
                            title="Vitaminas"
                            columns={[
                                { key: "name",  title: "Nome",  sortable: true, headerClassName: "is-nowrap is-unselectable",                rowClassName: "is-nowrap" },
                                { key: "value", title: "Valor", sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right", rowClassName: "is-nowrap has-text-right", formatter: dynamicFormatter },
                            ]}
                            rows={vitaminas}
                            orderBy="value"
                            orderAscending={false}
                        />
                    </div>
                    <div className="column">
                        <Table
                            title="Minerais"
                            columns={[
                                { key: "name",  title: "Nome",  sortable: true, headerClassName: "is-nowrap is-unselectable",                rowClassName: "is-nowrap" },
                                { key: "value", title: "Valor", sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right", rowClassName: "is-nowrap has-text-right", formatter: dynamicFormatter },
                            ]}
                            rows={minerais}
                            orderBy="value"
                            orderAscending={false}
                        />
                    </div>
                </div>
            </Page>
        );
    }
}

export default FoodDetails;
