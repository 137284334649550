import { Component } from 'react';
import { session } from '../../lib/api';

class SignOut extends Component {

    componentDidMount() {
        session.signout()
            .then(() => {
                this.props.onSignOut();
            });
    }

    render() {
        return null;
    }
}

export default SignOut;
