import React, { Component } from 'react';
import Page from '../common/Page';
import { loadStripe } from '@stripe/stripe-js';

// stripe public key
const stripePromise = loadStripe('pk_live_51HQI9wLiAenxeDaX71YUjwGHlnMhc6wkle3PTbFpeJv7UOkdEsTc4bX5htX7JpOU8Fk8Hbatre6t8hgop3WKJH3f00trbAhq4x');
// const stripePromise = loadStripe('pk_test_51HQI9wLiAenxeDaXmwmATq1fpsWeBxMRIilQvIjv1fYrkraNCi4PCXtexSObsIilGJelCZTZTHat0x5qbv7Dgv7v00ps5T9ROK');

class ConfigSubscribe extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false
        };
    }

    async onCheckout(type) {
        this.setState({
            loading: true
        });

        const stripe = await stripePromise;
    
        // create the Checkout session
        const response = await fetch(`/api/subscription/${type}`, { method: 'POST' });
        const session = await response.json();
    
        // redirect user to Checkout
        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });
    
        // browser or network error
        if (result.error) {
            console.log(result.error.message);
        }
    }

    renderGreen(text) {
        return (
            <div className="panel-block px-5">
                <span className="panel-icon"><i className="fas fa-check has-text-success" aria-hidden="true"></i></span>{text}
            </div>
        );
    }

    renderYellow(text) {
        return (
            <div className="panel-block px-5">
                <span className="panel-icon"><i className="fas fa-check has-text-warning" aria-hidden="true"></i></span>{text}
            </div>
        );
    }

    renderRed(text) {
        return (
            <div className="panel-block px-5">
                <span className="panel-icon"><i className="fas fa-times has-text-danger" aria-hidden="true"></i></span>{text}
            </div>
        );
    }

    renderInfinity(text) {
        return (
            <div className="panel-block px-5">
                <span className="panel-icon"><i className="fas fa-check-double has-text-success" aria-hidden="true"></i></span>{text}
            </div>
        );
    }

    renderBasePanel() {
        const color = "success";

        return (
            <nav className="panel has-background-white">
                <div className="panel-block has-text-grey-dark" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="is-size-4 has-text-weight-bold my-3">Essencial</div>
                    <div className="mb-0">
                        <span className={`is-size-2 has-text-weight-bold mr-2 has-text-${color}`}>10€</span>/<span className="is-size-6 has-text-weight-normal ml-1">mês</span>
                    </div>
                    <div className="mb-3">
                        <div className={`tag has-text-weight-bold is-${color}`}>Poupe 20% com o plano anual</div>
                    </div>
                    <div className="mb-3 has-text-centered is-size-7">
                        Plano ideal para empresas e instituições (por exemplo restaurantes, ginásios, hospitais, escolas, creches e lares) que criam fichas técnicas para apenas uma entidade.
                    </div>
                    <div className="mb-3 has-text-centered is-size-7">
                        10.0€ por mês se faturado 120.0€ anualmente <br/>
                        12.5€ por mês se faturado 37.5€ trimestralmente
                    </div>
                </div>
                { this.renderYellow("1 utilizador") }
                { this.renderYellow("1 entidade") }
                { this.renderYellow("100 receitas / fichas técnicas") }
                { this.renderGreen("Ingredientes ilimitados") }
                { this.renderGreen("1275 alimentos com 40 valores nutricionais") }
                { this.renderGreen("14 alergénios") }
                { this.renderGreen("Percentagem edível automática") }
                { this.renderGreen("Cálculo nutricional automático") }
                { this.renderRed("Alimentos e alergénios personalizados") }
                <div className="panel-block" style={{ display: 'flex', flexDirection: 'column' }}>
                    <button className={`button is-fullwidth is-${color} ${this.state.loading ? "is-loading" : ""}`} onClick={() => this.onCheckout('simple_yearly')}>
                        Subscrever Plano Anual
                    </button>
                    <button className={`button is-fullwidth mt-2 ${this.state.loading ? "is-loading" : ""}`} onClick={() => this.onCheckout('simple_monthly')}>
                        Subscrever Plano Trimestral
                    </button>
                </div>
            </nav>
        );
    }

    renderProPanel() {
        const color = "warning";

        return (
            <nav className="panel has-background-white">
                <div className="panel-block has-text-grey-dark" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="is-size-4 has-text-weight-bold my-3">Profissional</div>
                    <div className="mb-0">
                        <span className={`is-size-2 has-text-weight-bold mr-2 has-text-${color}`}>30€</span>/<span className="is-size-6 has-text-weight-normal ml-1">mês</span>
                    </div>
                    <div className="mb-3">
                        <div className={`tag has-text-weight-bold is-${color}`}>Poupe 20% com o plano anual</div>
                    </div>
                    <div className="mb-3 has-text-centered is-size-7">
                        Plano ideal para profissionais, empresas e instituições (por exemplo nutricionistas, câmaras municipais, juntas de freguesia e cadeias de restauração) que criam fichas técnicas para uma ou mais entidades.
                    </div>
                    <div className="mb-3 has-text-centered is-size-7">
                        30.0€ por mês se faturado 360.0€ anualmente <br/>
                        37.5€ por mês se faturado 112.5€ trimestralmente
                    </div>
                </div>
                { this.renderYellow("1 utilizador") }
                { this.renderGreen("Entidades ilimitadas") }
                { this.renderGreen("Receitas / fichas técnicas ilimitadas") }
                { this.renderGreen("Ingredientes ilimitados") }
                { this.renderGreen("1275 alimentos com 40 valores nutricionais") }
                { this.renderGreen("14 alergénios") }
                { this.renderGreen("Percentagem edível automática") }
                { this.renderGreen("Cálculo nutricional automático") }
                { this.renderGreen("Alimentos e alergénios personalizados (em breve) **") }
                <div className="panel-block" style={{ display: 'flex', flexDirection: 'column' }}>
                    <button className={`button is-fullwidth is-${color} ${this.state.loading ? "is-loading" : ""}`} onClick={() => this.onCheckout('professional_yearly')}>
                        Subscrever Plano Anual
                    </button>
                    <button className={`button is-fullwidth mt-2 ${this.state.loading ? "is-loading" : ""}`} onClick={() => this.onCheckout('professional_monthly')}>
                        Subscrever Plano Trimestral
                    </button>
                </div>
            </nav>
        );
    }

    renderCompanyPanel() {
        const color = "info";

        return (
            <nav className="panel has-background-white">
                <div className="panel-block has-text-grey-dark" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="is-size-4 has-text-weight-bold my-3">Corporativo</div>
                    <div className="mb-3">
                        <span className={`is-size-6 has-text-weight-bold mr-2`}>Sob Consulta</span>
                    </div>
                    <div className="mb-3 has-text-centered is-size-7">
                        Plano ideal para empresas com múltiplos profissionais que podem gerir as fichas técnicas colaborativamente.
                    </div>
                    <div className="mb-3 has-text-centered is-size-7">
                        Por favor entre em contato connosco<br/>
                        através do email <a href="mailto:info@nutrit.pt" target="_blank" rel="noreferrer">info@nutrit.pt</a>.
                    </div>
                </div>
                { this.renderGreen("Utilizadores ilimitados") }
                { this.renderGreen("Entidades ilimitadas") }
                { this.renderGreen("Receitas / fichas técnicas ilimitadas") }
                { this.renderGreen("Ingredientes ilimitados") }
                { this.renderGreen("1275 alimentos com 40 valores nutricionais") }
                { this.renderGreen("14 alergénios") }
                { this.renderGreen("Percentagem edível automática") }
                { this.renderGreen("Cálculo nutricional automático") }
                { this.renderGreen("Alimentos e alergénios personalizados (em breve) **") }
                <div className="panel-block">
                    <button className={`button is-fullwidth is-${color}`} disabled>
                        Sob Consulta
                    </button>
                </div>
            </nav>
        );
    }

    render() {
        return (
            <Page id="subscribe" title="Subscrever">
                <div className="columns" style={{ alignItems: 'flex-end' }}>
                    <div className="column is-4">
                        {this.renderBasePanel()}
                    </div>
                    <div className="column is-4">
                        {this.renderProPanel()}
                    </div>
                    <div className="column is-4">
                        {this.renderCompanyPanel()}
                    </div>
                </div>
                <div>
                    * Aos valores apresentados acresce IVA à taxa legal de 23%.<br/>
                    ** Permite criar novos ingredientes e alergénios e usá-los nas receitas.
                </div>
            </Page>
        );
    }
}

export default ConfigSubscribe;
