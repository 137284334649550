import React, { Component } from 'react';

class Page extends Component {

    render() {
        const normal = (
            <>
                <div className="mb-5" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {this.props.title &&
                    <div>
                        <h1 className="title has-text-primary">{this.props.title}</h1>
                        {this.props.subtitle &&
                        <h2 className="subtitle">{this.props.subtitle}</h2>
                        }
                    </div>
                    }
                    {this.props.controls &&
                    <div>
                        {this.props.controls}
                    </div>
                    }
                </div>
                <div className="content">
                    {this.props.children}
                </div>
            </>
        );

        const center = (
            <div className="column is-4 is-offset-4">
                {normal}
            </div>
        );

        return (
            <section id={this.props.id} className="section">
                <div className="container">
                    { this.props.center ? center : normal }
                </div>
            </section>
        );
    }
}

export default Page;
