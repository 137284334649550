import React, { Component } from 'react';
import { Input, Select, InputUnit } from '../common/Form';
import { set } from "lodash";
import { v4 as uuidv4 } from 'uuid';

class FoodForm extends Component {

    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        
        this.renderRow = this.renderRow.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.onMoveUp = this.onMoveUp.bind(this);
        this.onMoveDown = this.onMoveDown.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    onChange(name, value) {
        this.props.onChange(set([ ...this.props.value ], name, value));
    }

    onAdd() {
        const entries = [ ...this.props.value ];
        entries.push({ id: uuidv4(), food_id: "", quantity: "", edible_part: "", note: "" });
        this.props.onChange(entries);
    }

    onMoveUp(index) {
        if (index <= 0) {
            return;
        }

        const entries = [ ...this.props.value ];

        const value = entries[index - 1];
        entries[index - 1] = entries[index]
        entries[index] = value

        this.props.onChange(entries);
    }
    
    onMoveDown(index) {
        const entries = [ ...this.props.value ];

        if (index >= entries.length - 1) {
            return;
        }

        const value = entries[index + 1];
        entries[index + 1] = entries[index]
        entries[index] = value

        this.props.onChange(entries);
    }

    onDelete(index) {
        const entries = this.props.value.filter((v, i) => i !== index);
        this.props.onChange(entries);
    }

    renderRow(row, index) {
        const options = this.props.food && this.props.food.map((food) => {
            return {
                value: food.id,
                label: food.name
            };
        });

        return (
            <div key={row.id} className="field is-horizontal">
                <div className="field-body">
                    <Select
                        placeholder="Escolha um ingrediente..."
                        required={true}
                        value={row.food_id}
                        options={options}
                        onChange={(value) => {
                            this.onChange(`[${index}].food_id`, value);

                            if (value) {
                                const edible_part = this.props.food.find(f => f.id === value).edible_part;
                                this.onChange(`[${index}].edible_part`, edible_part);
                            } else {
                                this.onChange(`[${index}].edible_part`, "");
                            }
                        }}
                        style={{ width: '400px', maxWidth: '400px', minWidth: '400px' }}
                    />
                    <InputUnit
                        label="Gramas"
                        type="number"
                        min="1"
                        step="1"
                        placeholder="Gramas"
                        required={true}
                        value={row.quantity}
                        onChange={(value) => this.onChange(`[${index}].quantity`, value) }
                        style={{ width: '190px', maxWidth: '190px', minWidth: '190px' }}
                    />
                    <InputUnit
                        label="% Edível"
                        type="number"
                        min="1"
                        max="100"
                        step="1"
                        placeholder="%"
                        required={true}
                        value={row.edible_part}
                        onChange={(value) => this.onChange(`[${index}].edible_part`, value)}
                        style={{ width: '170px', maxWidth: '170px', minWidth: '170px' }}
                    />
                    <Input
                        placeholder="Introduza uma nota..."
                        value={row.note}
                        onChange={(value) => this.onChange(`[${index}].note`, value) }
                        style={{ minWidth: '100px' }}
                    />
                    <div className="buttons" style={{ flexWrap: 'nowrap', alignItems: 'flex-end' }}>
                        <button type="button" className="button is-light" disabled={index <= 0} onClick={(event) => this.onMoveUp(index)}>
                            <span className="icon is-small">
                                <i className="fas fa-arrow-up"></i>
                            </span>
                        </button>
                        <button type="button" className="button is-light" disabled={this.props.value && index >= this.props.value.length - 1} onClick={(event) => this.onMoveDown(index)}>
                            <span className="icon is-small">
                                <i className="fas fa-arrow-down"></i>
                            </span>
                        </button>
                        <button type="button" className="button is-light is-danger" onClick={(event) => this.onDelete(index)}>
                            <span className="icon is-small">
                                <i className="far fa-trash-alt"></i>
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        var foods = this.props.value;
        var rows = []

        if (foods) {
            // set state ids for React (will not be persisted)
            foods = foods.map(food => {
                return { id: uuidv4(), ...food };
            });

            rows = foods.map((food, i) => this.renderRow(food, i));
        }

        const isTrial = this.props.subscription.type === 'trial';

        return (
            <>
                { rows }
                <button type="button" className="button is-light is-success" disabled={isTrial && rows.length >= 2} onClick={() => this.onAdd()}>
                    <span className="icon is-small">
                        <i className="fas fa-plus"></i>
                    </span>
                    <span>Adicionar Ingrediente</span>
                </button>
            </>
        );
    }
}

export default FoodForm;
