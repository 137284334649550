import React, { Component } from 'react';
import { food } from '../../lib/api';
import Loading from '../common/Loading';
import Page from '../common/Page';
import PageTable from '../common/PageTable';

class FoodList extends Component {

    constructor(props) {
        super(props);

        this.state = {
            food: null,
            loading: true
        };

        this.onRowClick = this.onRowClick.bind(this);
    }

    componentDidMount() {
        food.list()
            .then(food => this.setState({
                food: food,
                loading: false
            }))
            .catch(error => this.setState({
                food: null,
                loading: true
            }));
    }

    onRowClick(id) {
        this.props.history.push(`/alimentos/${id}`);
    }

    render() {
        if (this.state.loading) {
            return (
                <Page title="Alimentos">
                    <Loading />
                </Page>
            );
        }

        const numberFormatter = (value, decimals, unit) => {
            if (value) {
                return `${value.toFixed(decimals)} ${unit}`;
            } else {
                return `${(0).toFixed(decimals)} ${unit}`;
            }
        }

        const kcalFormatter = (value) => numberFormatter(value, 0, "kcal");
        const gFormatter = (value) => numberFormatter(value, 1, "g");
        const mgFormatter = (value) => numberFormatter(value, 0, "mg");

        return (
            <PageTable
                title="Alimentos"
                className="is-hoverable"
                columns={[
                    { key: "name",                  title: "Nome",                abbreviation: null,       searchable: true,  sortable: true, headerClassName: "is-nowrap is-unselectable has-text-left",                                 rowClassName: "ellipsis" },
                    { key: "energia_kcal",          title: "Energia",             abbreviation: null,       searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: kcalFormatter },
                    { key: "lipidos_g",             title: "Lípidos",             abbreviation: null,       searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: gFormatter },
                    { key: "hidratos_de_carbono_g", title: "Hidratos de Carbono", abbreviation: "Hidratos", searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: gFormatter },
                    { key: "fibra_g",               title: "Fibra",               abbreviation: null,       searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: gFormatter },
                    { key: "proteinas_g",           title: "Proteínas",           abbreviation: null,       searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: gFormatter },
                    { key: "sal_g",                 title: "Sal",                 abbreviation: null,       searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: gFormatter },
                    { key: "alcool_g",              title: "Álcool",              abbreviation: null,       searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: gFormatter },
                    { key: "agua_g",                title: "Água",                abbreviation: null,       searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: gFormatter },
                    { key: "acidos_organicos_g",    title: "Ácidos Orgânicos",    abbreviation: "Ácidos",   searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: gFormatter },
                    { key: "colesterol_mg",         title: "Colesterol",          abbreviation: null,       searchable: false, sortable: true, headerClassName: "is-nowrap is-unselectable has-text-right is-hidden-mobile", rowClassName: "is-nowrap is-fit has-text-right is-hidden-mobile", formatter: mgFormatter },
                ]}
                rows={this.state.food}
                onRowClick={this.onRowClick}
                orderBy="name"
                orderAscending={true}
            />
        );
    }
}

export default FoodList;
