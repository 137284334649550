import React, { Component } from 'react';
import { recipe, food, allergen } from '../../lib/api';
import Loading from '../common/Loading';
import Page from '../common/Page';
import Table from '../common/Table';
import { kcalFormatter, kjFormatter, gFormatter, mgFormatter, µgFormatter, percentFormatter } from '../../lib/formatter';
import PieChart from '../common/PieChart';

class RecipeDetails extends Component {

    constructor(props) {
        super(props);

        this.state = {
            id: this.props.match.params.id,
            recipe: null,
            food: null,
            allergens: null,
            loading: true,
            panel: 'sumario'
        };

        this.onEdit = this.onEdit.bind(this);
        this.onDelete = this.onDelete.bind(this);
    }

    componentDidMount() {
        Promise.all([recipe.get(this.state.id), food.list(), allergen.list()])
            .then(([ recipe, food, allergens ]) => {
                this.setState({
                    recipe: recipe,
                    food: food,
                    allergens: allergens,
                    loading: false
                });
            })
            .catch(error => {
                this.setState({
                    recipe: null,
                    food: null,
                    allergens: null,
                    loading: true
                });
            });
    }

    renderFood() {
        const food = (this.state.recipe.food || []).map((fm) => {
            const x = this.state.food.find((f) => f.id === fm.food_id);
            return { ...fm, ...x };
        });

        if (!food || food.length === 0) {
            return (
                <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="subtitle has-text-primary mb-0">Ingredientes</div>
                        <div className="tag is-primary is-light is-medium">
                            {this.state.recipe.portions} {this.state.recipe.portions === 1 ? "Porção" : "Porções"}
                        </div>
                    </div>
                    <div className="mt-2 has-text-grey-light is-italic">Sem Informação.</div>
                </>
            );
        }

        const noteFormatter = (value, row) => {
            return (
                <>
                    {value}
                    {row.note &&
                        <div style={{ color: '#6d6d6d', fontStyle: 'italic', display: 'inline' }}>
                            <span className="pl-1 pr-1">‒</span><span>{ row.note }</span>
                        </div>
                    }
                </>
            );
        }

        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="subtitle has-text-primary mb-0">Ingredientes</div>
                    <div className="tag is-primary is-light is-medium">
                        {this.state.recipe.portions} {this.state.recipe.portions === 1 ? "Porção" : "Porções"}
                    </div>
                </div>
                <Table
                    className="nostyle-2"
                    columns={[
                        { key: "quantity", headerClassName: "is-nowrap is-unselectable has-text-right",   rowClassName: "is-nowrap is-fit has-text-right", formatter: gFormatter },
                        { key: "name",     headerClassName: "is-nowrap is-unselectable",                  rowClassName: "pl-3", formatter: noteFormatter }
                    ]}
                    rows={food}
                    onRowClick={this.onRowClick}
                />
            </>
        );
    }

    renderAllergens() {
        const food = (this.state.recipe.allergens || []).map((fm) => {
            const x = this.state.allergens.find((f) => f.id === fm.allergen_id);
            return { ...fm, ...x };
        });

        if (!food || food.length === 0) {
            return (
                <div>
                    <div className="subtitle has-text-primary mb-0">Alergénios</div>
                    <div className="mt-2 has-text-grey-light is-italic">Sem Informação.</div>
                </div>
            );
        }

        return (
            <Table
                title="Alergénios"
                className="nostyle-6"
                columns={[
                    { key: "name" }
                ]}
                rows={food}
                onRowClick={this.onRowClick}
            />
        );
    }

    renderInstructions() {
        if (!this.state.recipe.instructions || this.state.recipe.instructions.length === 0) {
            return (
                <div>
                    <div className="subtitle has-text-primary mb-0">Realização</div>
                    <div className="mt-2 has-text-grey-light is-italic">Sem Informação.</div>
                </div>
            );
        }

        return (
            <div>
                <span className="subtitle has-text-primary">Realização</span>
                <div style={{ whiteSpace: 'pre-wrap', marginTop: '1em' }}>
                    {this.state.recipe.instructions}
                </div>
            </div>
        );
    }

    renderSecurity() {
        if (!this.state.recipe.security || this.state.recipe.security.length === 0) {
            return (
                <div>
                    <div className="subtitle has-text-primary mb-0">Segurança Alimentar</div>
                    <div className="mt-2 has-text-grey-light is-italic">Sem Informação.</div>
                </div>
            );
        }

        return (
            <div>
                <span className="subtitle has-text-primary">Segurança Alimentar</span>
                <div style={{ whiteSpace: 'pre-wrap', marginTop: '1em' }}>
                    {this.state.recipe.security}
                </div>
            </div>
        );
    }

    renderImage() {
        if (!this.state.recipe.image) {
            return null;
        }

        return (
            <div className="box">
                <img src={`/api/file/${this.state.recipe.image}/download`} alt="" />
            </div>
        );
    }

    renderPanel() {
        const composition = this.state.recipe.composition || {};
        const composition_100 = this.state.recipe.composition_100 || {};
        const composition_dr = this.state.recipe.composition_dr || {};

        const dynamicFormatter = (value, row) => row.formatter(value);

        const defaultColors = [
            '#ff6384',
            '#ff9f40',
            '#4bc0c0',
            '#36a2eb',
            '#ffcd56',
            '#B0B8BF',
            '#FF99AF',
            '#FFB870',
            '#68CACA',
            '#6ABBF0',
            '#FF476F',
            '#FF8F1F',
            '#3AA6A6',
            '#2099E9'
        ];

        var title = "";
        var chartData = [];
        var tableData = [];
        var colors = defaultColors;

        if (this.state.panel === 'sumario') {
            title = "Sumário";

            chartData = [
                { id: "lipidos_g",                 name: "Lípidos",                value: composition.lipidos_g,                                 formatter: gFormatter },
                { id: "hidratos_de_carbono_g",     name: "Hidratos de Carbono",    value: composition.hidratos_de_carbono_g,                     formatter: gFormatter },
                { id: "proteinas_g",               name: "Proteínas",              value: composition.proteinas_g,                               formatter: gFormatter },
                { id: "fibra_g",                   name: "Fibra",                  value: composition.fibra_g,                                   formatter: gFormatter },
                { id: "sal_g",                     name: "Sal",                    value: composition.sal_g,                                     formatter: gFormatter },
                { id: "outros",                    name: "Outros",                 value: composition.alcool_g + composition.agua_g + composition.acidos_organicos_g + composition.colesterol_mg / 1000.0,                                  formatter: gFormatter },
            ];

            tableData = [
                { id: "energia_kcal",              name: "Energia",                  value: <>{kjFormatter(composition.energia_kj)}<br/>{kcalFormatter(composition.energia_kcal)}</>, value_100: <>{kjFormatter(composition_100.energia_kj)}<br/>{kcalFormatter(composition_100.energia_kcal)}</>, value_dr: composition_dr.energia_kcal, formatter: (s) =>  s  },
                { id: "lipidos_g",                 name: "Lípidos",                  value: composition.lipidos_g,                 value_100: composition_100.lipidos_g,                 value_dr: composition_dr.lipidos_g,                 formatter: gFormatter },
                { id: "acidos_gordos_saturados_g", name: "   – dos quais saturados", value: composition.acidos_gordos_saturados_g, value_100: composition_100.acidos_gordos_saturados_g, value_dr: composition_dr.acidos_gordos_saturados_g, formatter: gFormatter },
                { id: "hidratos_de_carbono_g",     name: "Hidratos de Carbono",      value: composition.hidratos_de_carbono_g,     value_100: composition_100.hidratos_de_carbono_g,     value_dr: composition_dr.hidratos_de_carbono_g,     formatter: gFormatter },
                { id: "acucares_g",                name: "   – dos quais Açúcares",  value: composition.acucares_g,                value_100: composition_100.acucares_g,                value_dr: composition_dr.acucares_g,                formatter: gFormatter },
                { id: "proteinas_g",               name: "Proteínas",                value: composition.proteinas_g,               value_100: composition_100.proteinas_g,               value_dr: composition_dr.proteinas_g,               formatter: gFormatter },
                { id: "fibra_g",                   name: "Fibra",                    value: composition.fibra_g,                   value_100: composition_100.fibra_g,                   value_dr: composition_dr.fibra_g,                   formatter: gFormatter },
                { id: "sal_g",                     name: "Sal",                      value: composition.sal_g,                     value_100: composition_100.sal_g,                     value_dr: composition_dr.sal_g,                     formatter: gFormatter },
            ];
        } else if (this.state.panel === 'composicao') {
            title = "Composição";

            chartData = [
                { id: "lipidos_g",                 name: "Lípidos",              value: composition.lipidos_g,              value_100: composition_100.lipidos_g,              formatter: gFormatter },
                { id: "hidratos_de_carbono_g",     name: "Hidratos de Carbono",  value: composition.hidratos_de_carbono_g,  value_100: composition_100.hidratos_de_carbono_g,  formatter: gFormatter },
                { id: "proteinas_g",               name: "Proteínas",            value: composition.proteinas_g,            value_100: composition_100.proteinas_g,            formatter: gFormatter },
                { id: "fibra_g",                   name: "Fibra",                value: composition.fibra_g,                value_100: composition_100.fibra_g,                formatter: gFormatter },
                { id: "sal_g",                     name: "Sal",                  value: composition.sal_g,                  value_100: composition_100.sal_g,                  formatter: gFormatter },
                { id: "agua_g",                    name: "Água",                 value: composition.agua_g,                 value_100: composition_100.agua_g,                 formatter: gFormatter },
                { id: "alcool_g",                  name: "Álcool",               value: composition.alcool_g,               value_100: composition_100.alcool_g,               formatter: gFormatter },
                { id: "acidos_organicos_g",        name: "Ácidos Orgânicos",     value: composition.acidos_organicos_g,     value_100: composition_100.acidos_organicos_g,     formatter: gFormatter },
                { id: "colesterol_mg",             name: "Colesterol",           value: composition.colesterol_mg / 1000.0, value_100: composition_100.colesterol_mg / 1000.0, formatter: mgFormatter }
            ];

            tableData = chartData;
        } else if (this.state.panel === 'lipidos') {
            title = "Lípidos";

            chartData = [
                { id: "acidos_gordos_saturados_g",       name: "Saturados",       value: composition.acidos_gordos_saturados_g,       formatter: gFormatter },
                { id: "acidos_gordos_monoinsaturados_g", name: "Monoinsaturados", value: composition.acidos_gordos_monoinsaturados_g, formatter: gFormatter },
                { id: "acidos_gordos_polinsaturados_g",  name: "Polinsaturados",  value: composition.acidos_gordos_polinsaturados_g,  formatter: gFormatter },
                { id: "acido_linoleico_g",               name: "Linoleico",       value: composition.acido_linoleico_g,               formatter: gFormatter },
                { id: "acidos_gordos_trans_g",           name: "Trans",           value: composition.acidos_gordos_trans_g,           formatter: gFormatter }
            ];
    
            tableData = [
                { id: "acidos_gordos_saturados_g",       name: "Ácidos gordos saturados",       value: composition.acidos_gordos_saturados_g,       value_100: composition_100.acidos_gordos_saturados_g,       formatter: gFormatter },
                { id: "acidos_gordos_monoinsaturados_g", name: "Ácidos gordos monoinsaturados", value: composition.acidos_gordos_monoinsaturados_g, value_100: composition_100.acidos_gordos_monoinsaturados_g, formatter: gFormatter },
                { id: "acidos_gordos_polinsaturados_g",  name: "Ácidos gordos polinsaturados",  value: composition.acidos_gordos_polinsaturados_g,  value_100: composition_100.acidos_gordos_polinsaturados_g,  formatter: gFormatter },
                { id: "acido_linoleico_g",               name: "Ácido linoleico",               value: composition.acido_linoleico_g,               value_100: composition_100.acido_linoleico_g,               formatter: gFormatter },
                { id: "acidos_gordos_trans_g",           name: "Ácidos gordos trans",           value: composition.acidos_gordos_trans_g,           value_100: composition_100.acidos_gordos_trans_g,           formatter: gFormatter }
            ];
    
            colors = [
                '#CC002C',
                '#FF0A3F',
                '#FF476F',
                '#FF859F',
                '#FFC2CF'
            ];
        } else if (this.state.panel === 'hidratos') {
            title = "Hidratos de Carbono";
            
            chartData = [
                { id: "acucares_g",        name: "Açúcares",         value: composition.acucares_g,        value_100: composition_100.acucares_g,        formatter: gFormatter },
                { id: "oligossacaridos_g", name: "Oligossacaáridos", value: composition.oligossacaridos_g, value_100: composition_100.oligossacaridos_g, formatter: gFormatter },
                { id: "amido_g",           name: "Amido",            value: composition.amido_g,           value_100: composition_100.amido_g,           formatter: gFormatter }
            ];
    
            tableData = chartData;
    
            colors = [
                '#FF850A',
                '#FFA347',
                '#FFC285'
            ];
        } else if (this.state.panel === 'vitaminas') {
            title = "Vitaminas";

            chartData = [
                { id: "vitamina_a_µg",              name: "Vitamina A",              value: composition.vitamina_a_µg / 1000.0 / 1000.0,     value_100: composition_100.vitamina_a_µg / 1000.0 / 1000.0,     formatter: µgFormatter },
                { id: "caroteno_µg",                name: "Caroteno",                value: composition.caroteno_µg / 1000.0 / 1000.0,       value_100: composition_100.caroteno_µg / 1000.0 / 1000.0,       formatter: µgFormatter },
                { id: "vitamina_d_µg",              name: "Vitamina D",              value: composition.vitamina_d_µg / 1000.0 / 1000.0,     value_100: composition_100.vitamina_d_µg / 1000.0 / 1000.0,     formatter: µgFormatter },
                { id: "alfa_tocoferol_mg",          name: "Alfa-tocoferol",          value: composition.alfa_tocoferol_mg / 1000.0,          value_100: composition_100.alfa_tocoferol_mg / 1000.0,          formatter: mgFormatter },
                { id: "tiamina_mg",                 name: "Tiamina",                 value: composition.tiamina_mg / 1000.0,                 value_100: composition_100.tiamina_mg / 1000.0,                 formatter: mgFormatter },
                { id: "riboflavina_mg",             name: "Riboflavina",             value: composition.riboflavina_mg / 1000.0,             value_100: composition_100.riboflavina_mg / 1000.0,             formatter: mgFormatter },
                { id: "niacina_mg",                 name: "Niacina",                 value: composition.niacina_mg / 1000.0,                 value_100: composition_100.niacina_mg / 1000.0,                 formatter: mgFormatter },
                { id: "equivalentes_de_niacina_mg", name: "Equivalentes de niacina", value: composition.equivalentes_de_niacina_mg / 1000.0, value_100: composition_100.equivalentes_de_niacina_mg / 1000.0, formatter: mgFormatter },
                { id: "triptofano_60_mg",           name: "Triptofano/60",           value: composition.triptofano_60_mg / 1000.0,           value_100: composition_100.triptofano_60_mg / 1000.0,           formatter: mgFormatter },
                { id: "vitamina_b6_mg",             name: "Vitamina B6",             value: composition.vitamina_b6_mg / 1000.0,             value_100: composition_100.vitamina_b6_mg / 1000.0,             formatter: mgFormatter },
                { id: "vitamina_b12_µg",            name: "Vitamina B12",            value: composition.vitamina_b12_µg / 1000.0 / 1000.0,   value_100: composition_100.vitamina_b12_µg / 1000.0 / 1000.0,   formatter: µgFormatter },
                { id: "vitamina_c_mg",              name: "Vitamina C",              value: composition.vitamina_c_mg / 1000.0,              value_100: composition_100.vitamina_c_mg / 1000.0,              formatter: mgFormatter },
                { id: "folatos_µg",                 name: "Folatos",                 value: composition.folatos_µg / 1000.0 / 1000.0,        value_100: composition_100.folatos_µg / 1000.0 / 1000.0,        formatter: µgFormatter }
            ];;
    
            tableData = chartData;
        } else if (this.state.panel === 'minerais') {
            title = "Minerais";
            
            chartData = [
                { id: "cinza_g",     name: "Cinza",    value: composition.cinza_g,              value_100: composition_100.cinza_g,              formatter: gFormatter },
                { id: "sodio_mg",    name: "Sódio",    value: composition.sodio_mg / 1000.0,    value_100: composition_100.sodio_mg / 1000.0,    formatter: mgFormatter },
                { id: "potassio_mg", name: "Potássio", value: composition.potassio_mg / 1000.0, value_100: composition_100.potassio_mg / 1000.0, formatter: mgFormatter },
                { id: "calcio_mg",   name: "Cálcio",   value: composition.calcio_mg / 1000.0,   value_100: composition_100.calcio_mg / 1000.0,   formatter: mgFormatter },
                { id: "fosforo_mg",  name: "Fósforo",  value: composition.fosforo_mg / 1000.0,  value_100: composition_100.fosforo_mg / 1000.0,  formatter: mgFormatter },
                { id: "magnesio_mg", name: "Magnésio", value: composition.magnesio_mg / 1000.0, value_100: composition_100.magnesio_mg / 1000.0, formatter: mgFormatter },
                { id: "ferro_mg",    name: "Ferro",    value: composition.ferro_mg / 1000.0,    value_100: composition_100.ferro_mg / 1000.0,    formatter: mgFormatter },
                { id: "zinco_mg",    name: "Zinco",    value: composition.zinco_mg / 1000.0,    value_100: composition_100.zinco_mg / 1000.0,    formatter: mgFormatter }
            ];

            tableData = chartData;
        }

        return (
            <div className="box">
                <div className="mb-5" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div className="subtitle has-text-primary mb-0">
                        {title}
                        <div className="dropdown is-hoverable">
                            <div className="dropdown-trigger" style={{ cursor: 'pointer' }}>
                                <span className="icon is-small ml-2">
                                    <i className="fas fa-caret-square-down" aria-hidden="true"></i>
                                </span>
                            </div>
                            <div className="dropdown-menu">
                                <div className="dropdown-content">
                                    <a className={`dropdown-item ${this.state.panel === 'sumario' ?    'is-active' : ''}`} onClick={(ev) => this.onPanelChange('sumario')}>Sumário</a>
                                    <a className={`dropdown-item ${this.state.panel === 'composicao' ? 'is-active' : ''}`} onClick={(ev) => this.onPanelChange('composicao')}>Composição</a>
                                    <a className={`dropdown-item ${this.state.panel === 'lipidos' ?    'is-active' : ''}`} onClick={(ev) => this.onPanelChange('lipidos')}>Lípidos</a>
                                    <a className={`dropdown-item ${this.state.panel === 'hidratos' ?   'is-active' : ''}`} onClick={(ev) => this.onPanelChange('hidratos')}>Hidratos de Carbono</a>
                                    <a className={`dropdown-item ${this.state.panel === 'vitaminas' ?  'is-active' : ''}`} onClick={(ev) => this.onPanelChange('vitaminas')}>Vitaminas</a>
                                    <a className={`dropdown-item ${this.state.panel === 'minerais' ?   'is-active' : ''}`} onClick={(ev) => this.onPanelChange('minerais')}>Minerais</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <PieChart
                        values={chartData}
                        colors={colors}
                    />
                </div>
                <Table
                    className="is-narrow is-striped"
                    columns={[
                        { key: "name", title: "", rowClassName: "is-nowrap", rowStyle: { whiteSpace: 'pre' } },
                        { key: "value_100", title: <>por 100g</>, rowClassName: "is-nowrap has-text-right", headerClassName: "is-nowrap has-text-right", headerStyle: { verticalAlign: "bottom" }, formatter: dynamicFormatter },
                        { key: "value", title: <>por porção</>, rowClassName: "is-nowrap has-text-right", headerClassName: "is-nowrap has-text-right", headerStyle: { verticalAlign: "bottom" }, formatter: dynamicFormatter },
                        { key: "value_dr", title: <>%DR*<br/>por porção</>, rowClassName: "is-nowrap has-text-right", headerClassName: "is-nowrap has-text-right", headerStyle: { verticalAlign: "bottom" }, formatter: percentFormatter }
                    ]}
                    rows={tableData}
                />
                <div className="mt-3" style={{ color: "#6d6d6d" }}>* DR - Dose de referência para um adulto médio (8400 kJ/2000 kcal)</div>
            </div>
        );
    }

    onEdit() {
        this.props.history.push(`/receitas/${this.state.recipe.id}/editar`);
    }

    onDelete() {
        if (!window.confirm(`Deseja mesmo apagar a receita "${this.state.recipe.name}"?`)) {
            return;
        }

        recipe.remove(this.state.recipe.id)
            .then(json => {
                this.props.history.push(`/receitas`);
            })
            .catch(error => {
                console.log(error);
            });
    }

    onPanelChange(id) {
        this.setState({
            panel: id
        });
    }

    render() {
        if (this.state.loading) {
            return (
                <Page>
                    <Loading />
                </Page>
            );
        }

        const controls = (
            <div className="buttons is-right">
                <a title="Exportar" className="button is-light is-primary is-small" href={`/api/recipe/${this.state.recipe.id}/download`} target="_blank" rel="noreferrer">
                    <span className="icon is-small">
                        <i className="fas fa-download"></i>
                    </span>
                    <span>Ficha Técnica</span>
                </a>
                <button title="Editar" className="button is-light is-info is-small" onClick={this.onEdit}>
                    <span className="icon is-small">
                        <i className="fas fa-pencil-alt"></i>
                    </span>
                    <span>Editar</span>
                </button>
                <button title="Apagar" className="button is-light is-danger is-small" onClick={this.onDelete}>
                    <span className="icon is-small">
                        <i className="far fa-trash-alt"></i>
                    </span>
                    <span>Apagar</span>
                </button>
            </div>
        );

        return (
            <Page id="recipe-details" title={this.state.recipe.name} controls={controls} subtitle={this.state.recipe.client && this.state.recipe.client.name}>
                <div className="columns is-desktop">
                    <div className="column is-three-fifths-desktop">
                        <div className="box">
                            {this.renderFood()}
                        </div>
                        <div className="box">
                            {this.renderAllergens()}
                        </div>
                        <div className="box">
                            {this.renderInstructions()}
                        </div>
                        <div className="box">
                            {this.renderSecurity()}
                        </div>
                    </div>
                    <div className="column is-two-fifths-desktop">
                        {this.renderImage()}
                        {this.renderPanel()}
                    </div>
                </div>
            </Page>
        );
    }
}

export default RecipeDetails;
