import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get, toLower } from "lodash";

class Table extends Component {

    constructor(props) {
        super(props);

        this.state = {
            query: "",
            orderBy: this.props.orderBy,
            orderAscending: this.props.orderAscending === false ? false : true,
        };

        this.onQueryChange = this.onQueryChange.bind(this);
        this.onQueryReset = this.onQueryReset.bind(this);
        this.getRows = this.getRows.bind(this);
    }

    onQueryChange(event) {
        this.setState({
            query: event.target.value
        });
    }

    onQueryReset(event) {
        this.setState({
            query: ""
        });
    }

    onColumnClick(column) {
        this.setState(prevState => {
            return {
                orderBy: column,
                orderAscending: prevState.orderBy === column ? !prevState.orderAscending : true
            };
        });
    }

    renderDirection(column) {
        if (this.state.orderBy === column) {
            return (
                <span style={{ verticalAlign: 'middle', color: '#AAA', marginLeft: '3px' }}>
                    <i className={ this.state.orderAscending ? "fas fa-sort-up" : "fas fa-sort-down" }></i>
                </span>
            );
        } else {
            return (
                <span style={{ verticalAlign: 'middle', color: '#EEE', marginLeft: '3px' }}>
                    <i className="fas fa-sort"></i>
                </span>
            );
        }
    }

    cleanValue(value) {
        if (!value) {
            return value;
        }

        // remove diacritics and lower case
        return value.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    }

    getRows() {
        if (!this.props.columns || !this.props.rows) {
            return [];
        }

        const query = this.state.query;

        var rows;

        // filter
        if (!query || query === '') {
            rows = this.props.rows;
        } else {
            const keys = this.props.columns
                .filter(column => column.searchable)
                .map(column => column.key);

            const cleanedQuery = this.cleanValue(query);

            rows = this.props.rows.filter(row => {
                return keys
                    .map(key => get(row, key))
                    .filter(value => value != null)
                    .some(value => this.cleanValue(value).indexOf(cleanedQuery) >= 0 )
            });
        }

        if (!this.state.orderBy) {
            return rows;
        }

        // sort
        rows = rows.sort((a, b) => {
            const one = a[this.state.orderBy];
            const another = b[this.state.orderBy];

            if (typeof one === "number" && typeof another === "number") {
                if (this.state.orderAscending) {
                    return one > another ? 1 : (one < another ? -1 : 0);
                } else {
                    return one > another ? -1 : (one < another ? 1 : 0);
                }
            } else if (typeof one === "boolean" && typeof another === "boolean") {
                if (this.state.orderAscending) {
                    return one ? 1 : (another ? -1 : 0);
                } else {
                    return one ? -1 : (another ? 1 : 0);
                }
            } else {
                if (!one && another) {
                    return 1;
                }
    
                if (one && !another) {
                    return -1;
                }

                const oneLower = toLower(get(a, this.state.orderBy));
                const anotherLower = toLower(get(b, this.state.orderBy));

                // compare strings
                if (this.state.orderAscending) {
                    return oneLower.localeCompare(anotherLower);
                } else {
                    return anotherLower.localeCompare(oneLower);
                }
            }
        });

        return rows;
    }

    render() {
        return (
            <div>
                <div className="mb-3" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div>
                        <span className="subtitle has-text-primary">{this.props.title}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        {this.props.columns && this.props.columns.filter(column => column.searchable).length > 0 &&
                        <div className={`control has-icons-left has-icons-right is-hidden-mobile ${this.props.actions && "mr-4"}`} style={{ minWidth: '300px' }} >
                            <input className="input is-rounded" type="text" placeholder="Procurar" value={this.state.query} onChange={this.onQueryChange} />
                            <span className="icon is-small is-left">
                                <i className="fas fa-search"></i>
                            </span>
                            {this.state.query &&
                            <span className="icon is-small is-right" onClick={this.onQueryReset} style={{ pointerEvents: 'all', cursor: 'pointer' }}>
                                <i className="fas fa-times"></i>
                            </span>
                            }
                        </div>
                        }
                        {this.props.actions}
                    </div>
                </div>
                <div className="table-container">
                    <table className={`table is-fullwidth ${this.props.className || ""}`}>
                        {this.props.columns && this.props.columns.some(column => column.title) &&
                        <thead>
                            <tr>
                                {this.props.columns && this.props.columns.map(column => {
                                    if (column.sortable) {
                                        return (
                                            <th key={column.key} className={column.headerClassName} style={{ cursor: 'pointer', ...column.headerStyle }} onClick={() => this.onColumnClick(column.key)}>
                                                {column.abbreviation && <abbr title={column.title}>{column.abbreviation}</abbr>}
                                                {!column.abbreviation && column.title}
                                                {this.renderDirection(column.key)}
                                            </th>
                                        );
                                    } else {
                                        return (
                                            <th key={column.key} className={column.headerClassName} style={column.headerStyle}>
                                                {column.abbreviation && <abbr title={column.title}>{column.abbreviation}</abbr>}
                                                {!column.abbreviation && column.title}
                                            </th>
                                        );
                                    }
                                })}
                                { this.props.inlineActions &&
                                <th className="is-hidden-mobile"></th>
                                }
                            </tr>
                        </thead>
                        }
                        <tbody>
                            {this.getRows().map((row, i) => {
                                const column = this.props.columns[i];

                                return (
                                    <tr key={row.id}>
                                        {this.props.columns.map(column => {
                                            var value = get(row, column.key);

                                            if (column.formatter) {
                                                value = column.formatter(value, row);
                                            }

                                            return (
                                                <td
                                                    key={`${row.id}-${column.key}`}
                                                    className={column && column.rowClassName}
                                                    style={{ verticalAlign: 'middle', ...(this.props.onRowClick ? { cursor: 'pointer'} : {}), ...column.rowStyle }}
                                                    onClick={() => this.props.onRowClick && this.props.onRowClick(row.id, row)}
                                                >{value}</td>
                                            );
                                        }
                                        )}
                                        { this.props.inlineActions &&
                                        <td className="is-nowrap is-unselectable is-fit is-hidden-mobile">{this.props.inlineActions(row.id, row)}</td>
                                        }
                                    </tr>
                                )}
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

Table.propTypes = {
    title: PropTypes.string,
    columns: PropTypes.array,
    rows: PropTypes.array,
    actions: PropTypes.element,
    inlineActions: PropTypes.func,
    onRowClick: PropTypes.func,
    className: PropTypes.string,
    orderBy: PropTypes.string,
    orderAscending: PropTypes.bool
}

export default Table;
