import React, { Component } from 'react';
import Loading from '../common/Loading';
import Page from '../common/Page';
import { session, subscription, nutritionist } from '../../lib/api';
import { Link } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { dateFormatter } from '../../lib/formatter';

// stripe public key
loadStripe('pk_live_51HQI9wLiAenxeDaX71YUjwGHlnMhc6wkle3PTbFpeJv7UOkdEsTc4bX5htX7JpOU8Fk8Hbatre6t8hgop3WKJH3f00trbAhq4x');

class Config extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
            subscription: null,
            nutritionist: null,
            loading: true
        };
    }

    componentDidMount() {
        Promise.all([ session.get(), subscription.get(), nutritionist.get() ])
            .then(([ user, subscription, nutritionist ]) => {
                this.setState({
                    user: user,
                    subscription: subscription,
                    nutritionist: nutritionist,
                    loading: false
                });
            })
            .catch(error => {
                this.setState({
                    user: null,
                    subscription: null,
                    nutritionist: null,
                    loading: true
                });
            });
    }

    renderUser() {
        return (
            <div>
                <div className="subtitle has-text-primary mb-4">Utilizador</div>
                <table className="table nostyle-6">
                    <tbody>
                        <tr>
                            <th className="has-text-left">Nome:</th>
                            <td>{this.state.user.name}</td>
                        </tr>
                        <tr>
                            <th className="has-text-left">Email:</th>
                            <td>{this.state.user.email}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderNutritionist() {
        return (
            <div>
                <div className="mb-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="subtitle has-text-primary mb-0">Nutricionista</div>
                    <Link to='/config/nutritionista' className="button is-light is-info is-small">
                        <span className="icon is-small">
                            <i className="fas fa-pencil-alt"></i>
                        </span>
                        <span>Editar</span>
                    </Link>
                </div>
                <table className="table nostyle-6">
                    <tbody>
                        <tr>
                            <th className="has-text-left">Nome:</th>
                            <td>{this.state.nutritionist.name}</td>
                        </tr>
                        <tr>
                            <th className="has-text-left">Descrição:</th>
                            <td style={{ whiteSpace: 'pre' }}>{this.state.nutritionist.description}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    async onDeleteSubscription() {
        if (!window.confirm(`Deseja mesmo cancelar a sua subscrição?`)) {
            return;
        }

        await fetch(`/api/subscription`, { method: 'DELETE' });
        window.location.reload();
    }

    getSubscriptionName(type) {
        switch (type) {
            case 'trial': return "Experimental";
            case 'lifetime': return "Vitalício";
            case 'simple_monthly': return "Simples";
            case 'simple_yearly': return "Simples";
            case 'professional_monthly': return "Profissional";
            case 'professional_yearly': return "Profissional";
            default: return "Não Definido";
        }
    }

    getSubscriptionRecurrence(type) {
        switch (type) {
            case 'simple_monthly':
            case 'professional_monthly':
                return "Mensal";
            case 'simple_yearly':
            case 'professional_yearly':
                return "Anual";
            default:
                return "Não Aplicável";
        }
    }

    renderSubscription() {
        return (
            <div>
                <div className="mb-4" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className="subtitle has-text-primary mb-0">Subscrição</div>
                    {(this.state.subscription.type === 'trial' || this.state.subscription.cancelled_at) &&
                    <Link to='/config/subscrever' className="button is-primary is-small">
                        <span className="icon is-small">
                            <i className="fas fa-arrow-circle-right"></i>
                        </span>
                        <span>Subscrever</span>
                    </Link>
                    }
                    {(!this.state.subscription.cancelled_at && this.state.subscription.type !== 'trial' && this.state.subscription.type !== 'lifetime') &&
                    <button className="button is-danger is-light is-small" onClick={this.onDeleteSubscription}>
                        <span className="icon is-small">
                            <i className="far fa-trash-alt"></i>
                        </span>
                        <span>Cancelar Subscrição</span>
                    </button>
                    }
                </div>
                <table className="table nostyle-6">
                    <tbody>
                        <tr>
                            <th className="has-text-left">Plano:</th>
                            <td>{this.getSubscriptionName(this.state.subscription.type)} {this.state.subscription.cancelled_at ? "(Cancelado)" : ""}</td>
                        </tr>
                        <tr>
                            <th className="has-text-left">Periodicidade:</th>
                            <td>{this.getSubscriptionRecurrence(this.state.subscription.type)}</td>
                        </tr>
                        <tr>
                            <th className="has-text-left">Data de inicio:</th>
                            <td>{dateFormatter(new Date(this.state.subscription.started_at))}</td>
                        </tr>
                        <tr>
                            <th className="has-text-left">Data de fim:</th>
                            <td>
                                {this.state.subscription.type === 'lifetime' && "-"}
                                {this.state.subscription.type !== 'lifetime' && this.state.subscription.renewed_at && dateFormatter(new Date(this.state.subscription.renewed_at))}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        if (this.state.loading) {
            return (
                <Page>
                    <Loading />
                </Page>
            );
        }

        return (
            <Page id="config" title="Configurações">
                <div className="columns is-desktop is-multiline">
                    <div className="column is-half-desktop is-one-third-widescreen">
                        <div className="box">
                            {this.renderUser()}
                        </div>
                    </div>
                    <div className="column is-half-desktop is-one-third-widescreen">
                        <div className="box">
                            {this.renderNutritionist()}
                        </div>
                    </div>
                    <div className="column is-half-desktop is-one-third-widescreen">
                        <div className="box">
                            {this.renderSubscription()}
                        </div>
                    </div>
                </div>
            </Page>
        );
    }
}

export default Config;
