import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Pricing extends Component {

    renderGreen(text) {
        return (
            <div className="panel-block px-5">
                <span className="panel-icon"><i className="fas fa-check has-text-success" aria-hidden="true"></i></span>{text}
            </div>
        );
    }

    renderYellow(text) {
        return (
            <div className="panel-block px-5">
                <span className="panel-icon"><i className="fas fa-check has-text-warning" aria-hidden="true"></i></span>{text}
            </div>
        );
    }

    renderRed(text) {
        return (
            <div className="panel-block px-5">
                <span className="panel-icon"><i className="fas fa-times has-text-danger" aria-hidden="true"></i></span>{text}
            </div>
        );
    }

    renderInfinity(text) {
        return (
            <div className="panel-block px-5">
                <span className="panel-icon"><i className="fas fa-check-double has-text-success" aria-hidden="true"></i></span>{text}
            </div>
        );
    }

    renderButton(buttonClassName) {
        if (this.props.user) {
            if (this.props.subscription.type === 'trial' || this.props.subscription.cancelled_at) {
                return (
                    <div className="panel-block">
                        <Link to="/config/subscrever" className={`button is-fullwidth ${buttonClassName}`}>
                            Subscrever
                        </Link>
                    </div>
                );
            } else {
                return (
                    <div className="panel-block">
                        <Link to="/config" className={`button is-fullwidth ${buttonClassName}`}>
                            Gerir Subscrição
                        </Link>
                    </div>
                );
            }
        }

        return (
            <div className="panel-block">
                <Link to="/registar" className={`button is-fullwidth ${buttonClassName}`}>
                    Subscrever
                </Link>
            </div>
        );
    }

    renderSoonButton(buttonClassName) {
        return (
            <div className="panel-block">
                <button className={`button is-fullwidth ${buttonClassName}`} disabled>
                    Sob Consulta
                </button>
            </div>
        );
    }

    renderTrialPanel() {
        return (
            <nav className="panel has-background-white">
                <div className="panel-block has-text-grey-dark" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="is-size-4 has-text-weight-bold my-3">Experimental</div>
                    <div className="mb-3">
                        <span className={"is-size-2 has-text-weight-bold mr-2"}>Grátis</span>/<span className="is-size-6 has-text-weight-normal ml-1">7 dias</span>
                    </div>
                    <div className="mb-3 has-text-centered is-size-7">
                        Experimente sem qualquer custo ou compromisso.
                    </div>
                </div>
                { this.renderYellow("1 utilizador") }
                { this.renderYellow("1 entidade") }
                { this.renderYellow("3 receitas / fichas técnicas") }
                { this.renderYellow("2 ingredientes") }
                { this.renderGreen("1275 alimentos com 40 valores nutricionais") }
                { this.renderGreen("14 alergénios") }
                { this.renderGreen("Percentagem edível automática") }
                { this.renderGreen("Cálculo nutricional automático") }
                { this.renderRed("Alimentos e alergénios personalizados") }
                { this.renderButton("") }
            </nav>
        );
    }

    renderBasePanel() {
        const color = "success";

        return (
            <nav className="panel has-background-white">
                <div className="panel-block has-text-grey-dark" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="is-size-4 has-text-weight-bold my-3">Essencial</div>
                    <div className="mb-0">
                        <span className={`is-size-2 has-text-weight-bold mr-2 has-text-${color}`}>10€</span>/<span className="is-size-6 has-text-weight-normal ml-1">mês</span>
                    </div>
                    <div className="mb-3">
                        <div className={`tag has-text-weight-bold is-${color}`}>Poupe 20% com o plano anual</div>
                    </div>
                    <div className="mb-3 has-text-centered is-size-7">
                        Plano ideal para empresas e instituições (por exemplo restaurantes, ginásios, hospitais, escolas, creches e lares) que criam fichas técnicas para apenas uma entidade.
                    </div>
                    <div className="mb-3 has-text-centered is-size-7">
                        10.0€ por mês se faturado 120.0€ anualmente <br/>
                        12.5€ por mês se faturado 37.5€ trimestralmente
                    </div>
                </div>
                { this.renderYellow("1 utilizador") }
                { this.renderYellow("1 entidade") }
                { this.renderYellow("100 receitas / fichas técnicas") }
                { this.renderGreen("Ingredientes ilimitados") }
                { this.renderGreen("1275 alimentos com 40 valores nutricionais") }
                { this.renderGreen("14 alergénios") }
                { this.renderGreen("Percentagem edível automática") }
                { this.renderGreen("Cálculo nutricional automático") }
                { this.renderRed("Alimentos e alergénios personalizados") }
                { this.renderButton(`is-${color}`) }
            </nav>
        );
    }

    renderProPanel() {
        const color = "warning";

        return (
            <nav className="panel has-background-white">
                <div className="panel-block has-text-grey-dark" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="is-size-4 has-text-weight-bold my-3">Profissional</div>
                    <div className="mb-0">
                        <span className={`is-size-2 has-text-weight-bold mr-2 has-text-${color}`}>30€</span>/<span className="is-size-6 has-text-weight-normal ml-1">mês</span>
                    </div>
                    <div className="mb-3">
                        <div className={`tag has-text-weight-bold is-${color}`}>Poupe 20% com o plano anual</div>
                    </div>
                    <div className="mb-3 has-text-centered is-size-7">
                        Plano ideal para profissionais, empresas e instituições (por exemplo nutricionistas, câmaras municipais, juntas de freguesia e cadeias de restauração) que criam fichas técnicas para uma ou mais entidades.
                    </div>
                    <div className="mb-3 has-text-centered is-size-7">
                        30.0€ por mês se faturado 360.0€ anualmente <br/>
                        37.5€ por mês se faturado 112.5€ trimestralmente
                    </div>
                </div>
                { this.renderYellow("1 utilizador") }
                { this.renderGreen("Entidades ilimitadas") }
                { this.renderGreen("Receitas / fichas técnicas ilimitadas") }
                { this.renderGreen("Ingredientes ilimitados") }
                { this.renderGreen("1275 alimentos com 40 valores nutricionais") }
                { this.renderGreen("14 alergénios") }
                { this.renderGreen("Percentagem edível automática") }
                { this.renderGreen("Cálculo nutricional automático") }
                { this.renderGreen("Alimentos e alergénios personalizados (em breve) **") }
                { this.renderButton(`is-${color}`) }
            </nav>
        );
    }

    renderCompanyPanel() {
        const color = "info";

        return (
            <nav className="panel has-background-white">
                <div className="panel-block has-text-grey-dark" style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className="is-size-4 has-text-weight-bold my-3">Corporativo</div>
                    <div className="mb-3">
                        <span className={`is-size-6 has-text-weight-bold mr-2`}>Sob Consulta</span>
                    </div>
                    <div className="mb-3 has-text-centered is-size-7">
                        Plano ideal para empresas com múltiplos profissionais que podem gerir as fichas técnicas colaborativamente.
                    </div>
                    <div className="mb-3 has-text-centered is-size-7">
                        Por favor entre em contato connosco<br/>
                        através do email <a href="mailto:info@nutrit.pt" target="_blank" rel="noreferrer">info@nutrit.pt</a>.
                    </div>
                </div>
                { this.renderGreen("Utilizadores ilimitados") }
                { this.renderGreen("Entidades ilimitadas") }
                { this.renderGreen("Receitas / fichas técnicas ilimitadas") }
                { this.renderGreen("Ingredientes ilimitados") }
                { this.renderGreen("1275 alimentos com 40 valores nutricionais") }
                { this.renderGreen("14 alergénios") }
                { this.renderGreen("Percentagem edível automática") }
                { this.renderGreen("Cálculo nutricional automático") }
                { this.renderGreen("Alimentos e alergénios personalizados (em breve) **") }
                { this.renderSoonButton(`is-${color}`) }
            </nav>
        );
    }

    render() {
        return (
            <>
                <section id="precario" className="section is-medium">
                    <div className="container">
                        <header>
                            <p className="title has-text-grey-dark has-text-centered">Preçário</p>
                        </header>
                        <div className="columns" style={{ alignItems: 'flex-end' }}>
                            <div className="column">
                                {this.renderTrialPanel()}
                            </div>
                            <div className="column">
                                {this.renderBasePanel()}
                            </div>
                            <div className="column">
                                {this.renderProPanel()}
                            </div>
                            <div className="column">
                                {this.renderCompanyPanel()}
                            </div>
                        </div>
                        <div className="has-text-grey">
                            * Aos valores apresentados acresce IVA à taxa legal de 23%.<br/>
                            ** Permite criar novos ingredientes e alergénios e usá-los nas receitas.
                        </div>
                    </div>
                </section>

            </>
        );
    }
}

export default Pricing;
