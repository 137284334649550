import React, { Component } from 'react';
import { client } from '../../lib/api';
import Page from '../common/Page';
import Loading from '../common/Loading';
import Form, { Input } from '../common/Form';

class ClientAdd extends Component {

    constructor(props) {
        super(props);

        this.defaultState = {
            id: this.props.match.params.id,
            form: {
                name: "",
                vat: "",
                location: ""
            },
            loading: this.props.match.params.id ? true : false,
            error: false
        };

        this.state = this.defaultState;

        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onFormCancel = this.onFormCancel.bind(this);
    }

    componentDidMount() {
        if (this.state.id) {
            client.get(this.state.id)
                .then(client => this.setState({
                    form: {
                        name: client.name,
                        vat: client.vat,
                        location: client.location
                    },
                    loading: false
                }))
                .catch(error => this.setState({
                    clients: client,
                    loading: true
                }));
        }
    }

    onChange(name, value) {
        this.setState(prevState => {
            return {
                form: {
                    ...prevState.form,
                    [name]: value
                },
                error: false
            };
        });
    }

    onFormSubmit(event) {
        event.preventDefault();

        const request = this.state.id ? client.edit(this.state.id, this.state.form) : client.add(this.state.form);

        request
            .then(client => {
                this.props.history.push("/clientes");
            })
            .catch(error => {
                this.setState({
                    error: true
                });
            });
    }

    onFormCancel(event) {
        this.props.history.push("/clientes");
    }

    render() {
        const action = this.state.id ? "Editar" : "Adicionar";

        if (this.state.loading) {
            return (
                <Page title={`${action} Cliente`}>
                    <Loading />
                </Page>
            );
        }

        return (
            <Page title={`${action} Cliente`}>
                <Form submitLabel={action} cancelLabel="Cancelar" onSubmit={this.onFormSubmit} onCancel={this.onFormCancel}>
                    <Input
                        label="Nome"
                        placeholder="Nome"
                        required={true}
                        value={this.state.form.name}
                        onChange={(value) => this.onChange("name", value) }
                        style={{ maxWidth: '400px' }}
                        error={this.state.error}
                    />
                    <Input
                        label="NIF / NIPC"
                        placeholder="NIF / NIPC"
                        value={this.state.form.vat}
                        onChange={(value) => this.onChange("vat", value) }
                        style={{ maxWidth: '400px' }}
                        error={this.state.error}
                    />
                    <Input
                        label="Localidade"
                        placeholder="Localidade"
                        value={this.state.form.location}
                        onChange={(value) => this.onChange("location", value) }
                        style={{ maxWidth: '400px' }}
                        error={this.state.error}
                    />
                </Form>
            </Page>
        );
    }
}

export default ClientAdd;
