import React, { Component } from 'react';
import Page from '../common/Page';
import { Link } from 'react-router-dom';

class CheckoutSuccess extends Component {

    render() {
        return (
            <Page id="checkout-success" title="Obrigado pela sua subscrição!">
                <p>Dentro de momentos irá receber um email a confirmar o seu pagamento.<br/>Por favor verifique na secção de <Link to='/config'>configurações</Link> se a sua subscrição está correcta.</p>
                <p>Caso detecte alguma irregularidade contacte-nos através do email <a href="mailto:info@nutrit.pt" target="_blank" rel="noreferrer">info@nutrit.pt</a>.</p>
            </Page>
        );
    }
}

export default CheckoutSuccess;
