import React from 'react';
import { Link } from 'react-router-dom';

const Privacy = () => {
    return (
        <section id="privacy" className="section">
            <div className="container content">
                <h1 className="title has-text-primary">Política de Privacidade</h1>

                <p>Na <b>Nutrit</b>, acessível a partir de <a href="https://nutrit.pt">https://nutrit.pt</a>, uma das nossas principais prioridades é a privacidade dos nossos visitantes. Este documento contém os tipos de informação que são coletadas e guardadas pela <b>Nutrit</b> e como essa informação é usada.</p>
                <p>Se tiver questões adicionais ou necessitar de mais informações sobre a nossa Política de Privacidade, não hesite em contacar-nos através do email <a href="mailto:info@bitstream.pt">info@bitstream.pt</a>.</p>
                <p>Esta Política de Privacidade aplica-se apenas às nossas atividades online e é válida para os visitantes do nosso website no que diz respeito às informações que partilham e / ou recolhem na <b>Nutrit</b>. Esta política não se aplica a nenhuma informação coletada offline ou por meio de outros canais que não este website.</p>

                <h2 className="subtitle has-text-primary mb-3">Consentimento</h2>
                <p>Ao usar o nosso website está a concordar com a nossa Política de Privacidade e com os nossos <Link to='/termos'>Termos e Condições</Link>.</p>

                <h2 className="subtitle has-text-primary mb-3">Informações que coletamos</h2>
                <p>As informações pessoais que fornecer e os motivos pelos quais deve fornecê-las ficarão claros no momento em que solicitarmos que forneça suas informações pessoais.</p>
                <p>Se contatar-nos diretamente, poderemos receber informações adicionais como seu nome, endereço de e-mail, número de telefone, o conteúdo da mensagem e / ou anexos que pode enviar-nos e qualquer outra informação que decida fornecer.</p>
                <p>Quando se registra para uma conta, podemos solicitar suas informações de contato, incluindo por exemplo o nome, nome da empresa, endereço, endereço de e-mail e número de telefone.</p>

                <h2 className="subtitle has-text-primary mb-3">Como usamos a suas informações</h2>
                <p>Usamos as informações que coletamos de várias formas, incluindo para:</p>
                <ul>
                    <li>Administrar, operar e manter nosso website;</li>
                    <li>Melhorar, personalizar e expandir o nosso website;</li>
                    <li>Compreenda e analisar como usa nosso website;</li>
                    <li>Desenvolver novos produtos, serviços, recursos e funcionalidades;</li>
                    <li>Comunicar consigo, diretamente ou por meio de um de nossos parceiros, inclusive para atendimento ao cliente, para fornecer-lhe atualizações e outras informações relacionadas ao website e para fins de marketing e promocionais;</li>
                    <li>Enviar-lhe e-mails;</li>
                    <li>Detectar e evite fraudes.</li>
                </ul>

                <h2 className="subtitle has-text-primary mb-3">Ficheiros de log</h2>
                <p>A <b>Nutrit</b> segue um procedimento padrão de uso de ficheiros de log. Esses ficheiros registram os visitantes quando eles visitam websites. Todas as empresas de alojamento web fazem isso e fazem parte das análises dos serviços de alojamento. As informações coletadas em ficheiros de log incluem endereços de protocolo de Internet (IP), tipo de navegador, provedor de serviços de Internet (ISP), data e hora, páginas de referência / saída e possivelmente o número de cliques. Eles não estão vinculados a nenhuma informação de identificação pessoal. O objetivo das informações é analisar tendências, administrar o website, rastrear o movimento dos utilizadores no website e coletar informações demográficas. </p>

                <h2 className="subtitle has-text-primary mb-3">Cookies e Web Beacons</h2>
                <p>Como qualquer outro website, a <b>Nutrit</b> usa "cookies". Esses cookies são usados para armazenar informações, incluindo as preferências dos visitantes e as páginas do website que o visitante acedeu ou visitou. As informações são usadas para otimizar a experiência dos utilizadores, personalizando o conteúdo do nossa website com base no tipo de navegador dos visitantes e / ou outras informações.</p>
                <p>Para obter mais informações gerais sobre cookies, pode ler <a href="https://www.cookieconsent.com/what-are-cookies/">"What Are Cookies"</a>.</p>

                <h2 className="subtitle has-text-primary mb-3">Google DoubleClick DART Cookies</h2>
                <p>O Google é um fornecedor terceiro de nosso website. Ele também usa cookies, conhecidos como DART Cookies, para veicular anúncios aos visitantes de nosso website com base em sua visita a <a href="https://nutrit.pt">https://nutrit.pt</a> e outros websites na Internet. No entanto, os visitantes podem optar por recusar o uso de DART Cookies visitando o anúncio do Google e a Política de Privacidade no seguinte URL - <a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a>.</p>

                <h2 className="subtitle has-text-primary mb-3">Nossos parceiros de publicidade</h2>
                <p>Alguns anunciantes em nosso website podem usar cookies e web beacons. Os nossos parceiros de publicidade estão listados abaixo. Cada um dos nossos parceiros de publicidade tem sua própria Política de Privacidade para suas políticas de dados do utilizador. Para facilitar o acesso, criámos uma hiperligação para suas Políticas de Privacidade abaixo. </p>
                <ul>
                    <li>
                        Google - <a href="https://policies.google.com/technologies/ads">https://policies.google.com/technologies/ads</a>
                    </li>
                </ul>

                <h2 className="subtitle has-text-primary mb-3">Políticas de privacidade de parceiros de publicidade</h2>
                <p>Servidores de anúncios de terceiros ou redes de anúncios usam tecnologias como cookies, JavaScript ou Web Beacons que são usados ​​em seus respectivos anúncios e links que aparecem na <b>Nutrit</b>, que são enviados diretamente ao navegador do utilizador. Eles recebem automaticamente o seu endereço IP quando isso ocorre. Essas tecnologias são usadas para medir a eficácia de suas campanhas publicitárias e / ou para personalizar o conteúdo publicitário que vê nos websites que visita.</p>
                <p>Note que a <b>Nutrit</b> não tem acesso ou controle sobre esses cookies que são usados ​​por anunciantes terceiros.</p>

                <h2 className="subtitle has-text-primary mb-3">Políticas de privacidade de terceiros</h2>
                <p>A Política de Privacidade da <b>Nutrit</b> não se aplica a outros anunciantes ou websites. Assim, aconselhamos que consulte as respectivas Políticas de Privacidade desses servidores de anúncios de terceiros para obter informações mais detalhadas.</p>
                <p>Pode optar por desativar os cookies nas opções individuais do navegador. Para saber informações mais detalhadas sobre o gerenciamento de cookies com navegadores web específicos, elas podem ser encontradas nos respectivos websites dos navegadores.</p>

                <h2 className="subtitle has-text-primary mb-3">Direitos de proteção de dados GDPR</h2>
                <p>Gostaríamos de ter certeza de que está totalmente ciente de todos os seus direitos de proteção de dados. Cada utilizador tem direito ao seguinte:</p>
                <ul>
                    <li>O direito de acesso - Tem o direito de solicitar cópias de seus dados pessoais. Podemos cobrar uma pequena taxa por esse serviço.</li>
                    <li>O direito de retificação - Tem o direito de solicitar a correção de qualquer informação que acredite ser imprecisa. Também tem o direito de solicitar o preenchimento das informações que acredita estar incompletas.</li>
                    <li>O direito de apagamento - Tem o direito de solicitar o apagamento de seus dados pessoais, sob certas condições.</li>
                    <li>O direito de restringir o processamento - Tem o direito de solicitar que restrinjamos o processamento dos seus dados pessoais, sob certas condições.</li>
                    <li>O direito de se opor ao processamento - Tem o direito de se opor ao nosso processamento de seus dados pessoais, sob certas condições.</li>
                    <li>O direito à portabilidade dos dados - Tem o direito de solicitar a transferência dos dados que coletamos para outra organização, ou diretamente para si, sob certas condições.</li>
                </ul>
                <p> Se fizer uma solicitação, temos um mês para responder-lhe. Se gostaria de exercitar qualquer um destes direitos, entre em contato connosco através do email <a href="mailto:info@bitstream.pt">info@bitstream.pt</a>.</p>

                <h2 className="subtitle has-text-primary mb-3">Informações infantis</h2>
                <p>Outra parte de nossa prioridade é adicionar proteção para crianças enquanto usam a Internet. Incentivamos os pais e responsáveis a observar, participar e / ou monitorar e orientar suas atividades online.</p>
                <p>A <b>Nutrit</b> não coleta intencionalmente nenhuma informação de identificação pessoal de crianças menores de 13 anos. Se acha que seu filho forneceu esse tipo de informação em nosso website, recomendamos que entre em contato connosco imediatamente e faremos nossos melhores esforços para remover imediatamente essas informações de nossos registros.</p>
            </div>
        </section>
    );
}

export default Privacy;
