import React from 'react';
import { default as ReactSelect, createFilter } from 'react-select';
import FileUpload from './FileUpload';

export const Input = ({ label, type, placeholder, required, value, onChange, style, error }) => {
    return (
        <div className="field" style={style || {}}>
            {label &&
            <label className="label">{label}</label>
            }
            <div className="control">
                <input className={`input ${error ? "is-danger" : ""}`} type={type || "text"} placeholder={placeholder || ""} required={required} value={value || ""} onChange={(event) => onChange(event.target.value)}/>
            </div>
        </div>
    );
}

export const TextArea = ({ label, placeholder, required, rows, value, onChange, style }) => {
    return (
        <div className="field" style={style || {}}>
            {label &&
            <label className="label">{label}</label>
            }
            <div className="control">
                <textarea className="textarea" placeholder={placeholder || ""} required={required} rows={rows} value={value || ""} onChange={(event) => onChange(event.target.value)}/>
            </div>
        </div>
    );
}

export const Select = ({ label, placeholder, required, value, options, onChange, style }) => {
    return (
        <div className="field" style={style || {}}>
            {label &&
            <label className="label">{label}</label>
            }
            <div className="control is-expanded">
                <ReactSelect
                    placeholder={placeholder}
                    required={required}
                    value={options && options.find((option) => option.value === value)}
                    options={options}
                    onChange={(event) => onChange(event && event.value || "")}
                    isClearable
                    isSearchable
                    filterOption={createFilter({
                        matchFrom: 'label',
                        stringify: option => `${option.label}`
                    })}
                    formatOptionLabel={({label}, {inputValue}) => <span dangerouslySetInnerHTML={{ __html: label.replace(new RegExp(inputValue, 'gi'), highlighted => `<mark>${highlighted}</mark>`) }} />}
                    classNamePrefix="react-select"
                />
                {required &&
                    <input
                        tabIndex={-1}
                        autoComplete="off"
                        style={{ position: 'absolute', padding: 0, margin: 0, height: 0, opacity: 0 }}
                        value={value}
                        onChange={() => null}
                        required={required}
                    />
                }
            </div>
        </div>
    );
}

export const InputUnit = ({ label, type, placeholder, required, value, onChange, style, error, min, max, step }) => {
    return (
        <div className="field has-addons" style={style || {}}>
            <div className="control is-expanded">
                <input
                    className={`input ${error ? "is-danger" : ""}`}
                    type={type || "text"}
                    placeholder={placeholder || ""}
                    required={required}
                    value={value !== null && value !== undefined ? value : ""}
                    onChange={(event) => onChange(event.target.value)}
                    min={min}
                    max={max}
                    step={step}
                />
            </div>
            <p className="control">
                <a className="button is-static">
                    {label}
                </a>
            </p>
        </div>
    );
}

export const File = ({ label, required, value, onChange }) => {
    return (
        <div className="field">
            {label &&
            <label className="label">{label}</label>
            }
            <div className="control" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <FileUpload accept="image/*" value={value} onChange={(file) => onChange(file)}/>
            </div>
        </div>
    );
}

const Form = ({ submitLabel, cancelLabel, onSubmit, onCancel, children }) => {
    return (
        <form onSubmit={onSubmit}>
            {children}
            <div className="field is-grouped mt-5">
                <div className="control">
                    <button type="submit" className="button is-primary">{submitLabel}</button>
                </div>
                <div className="control">
                    <button type="button" className="button is-light" onClick={onCancel}>{cancelLabel}</button>
                </div>
            </div>
        </form>
    );
}

export default Form;
