import React from 'react';
import { Link } from 'react-router-dom';

const Terms = () => {
    return (
        <section id="privacy" className="section">
            <div className="container content">
                <h1 className="title has-text-primary">Termos e Condições</h1>

                <p>Estes termos e condições definem as regras e regulamentos para a utilização do website <b>Nutrit</b>, o qual pertence à empresa <b>Bitstream, Unipessoal Lda</b> e está localizado em <a href="https://nutrit.pt">https://nutrit.pt</a>.</p>
                <p>Ao aceder a este website, presumimos que aceita estes termos e condições. Não continue a usar a <b>Nutrit</b> se não concordar com todos os termos e condições indicados nesta página.</p>
                <p>A terminologia a seguir aplica-se a estes Termos e Condições, <Link to='/privacidade'>Política de Privacidade</Link> e todos os Contratos: "Cliente", "Você" e "Seu" referem-se a você, a pessoa que visita este website e está em conformidade com os termos da Empresa e condições. "Empresa", "Nós", "Nosso" referem-se à nossa Empresa. "Parte" ou "Nós" referem-se ao Cliente e a nós mesmos. Todos os termos se referem à oferta, aceitação e consideração do pagamento necessário para realizar o processo de nossa assistência ao Cliente da maneira mais adequada para o propósito expresso de atender às necessidades do Cliente no que diz respeito à prestação dos serviços declarados da Empresa, de acordo com e sujeito à legislação em vigor em Portugal. Qualquer uso da terminologia acima ou outras palavras no singular, plural, maiúsculas e / ou ele / ela são considerados sinónimos e, portanto, como referindo-se aos mesmos.</p>

                <h2 className="subtitle has-text-primary mb-3">Cookies</h2>
                <p>Empregamos o uso de cookies. Ao aceder à <b>Nutrit</b>, concorda em utilizar cookies de acordo com a <Link to='/privacidade'>Política de Privacidade</Link>.</p>
                <p>A maioria dos websites interativos usa cookies para permitir recuperar os detalhes do utilizador em cada visita. Os cookies são usados ​​pelo nosso website para habilitar a funcionalidade de certas áreas para tornar mais fácil para as pessoas que visitam nosso website. Alguns de nossos afiliados / parceiros de publicidade também podem usar cookies.</p>

                <h2 className="subtitle has-text-primary mb-3">Licença</h2>
                <p>A fonte dos valores nutricionais dos alimentos é <a href="http://portfir.insa.pt/" target="_blank" rel="noreferrer">Tabela da Composição de Alimentos - Instituto Nacional de Saúde Doutor Ricardo Jorge, I. P.- INSA. v 4.1 - 2019</a>.</p>

                <p>Dito isto, salvo indicação em contrário, a <b>Bitstream, Unipessoal Lda</b> e / ou os seus licenciados detêm os direitos de propriedade intelectual de todo o material do <b>Nutrit</b>. Todos os direitos de propriedade intelectual são reservados. Você pode acessar à <b>Nutrit</b> para seu uso pessoal sujeito às restrições definidas nestes termos e condições.</p>
                <p>Você não deve:</p>
                <ul>
                    <li>Publicar novamente o material da <b>Nutrit</b>;</li>
                    <li>Vender, alugar ou sublicenciar material da <b>Nutrit</b>;</li>
                    <li>Reproduzir, duplicar ou copiar o material do <b>Nutrit</b>;</li>
                    <li>Redistribuir o conteúdo da <b>Nutrit</b>.</li>
                </ul>
                <p>Partes deste website oferecem aos usuários a oportunidade de postar eventos e trocar opiniões e informações em determinadas áreas do website. A <b>Bitstream, Unipessoal Lda</b> não filtra, edita, publica ou revê os eventos e comentários antes da sua presença no website. Os eventos e comentários não refletem as opiniões e pontos de vista da <b>Bitstream, Unipessoal Lda</b>, seus agentes e / ou afiliados. Os eventos e comentários refletem os pontos de vista e as opiniões da pessoa que postou seus pontos de vista e opiniões. Na medida do permitido pelas leis aplicáveis, <b>Bitstream, Unipessoal Lda</b> não será responsável pelos eventos e comentários ou por qualquer responsabilidade, danos ou despesas causados ​​e / ou sofridos como resultado de qualquer uso e / ou publicação de e / ou aparecimento dos eventos e comentários neste website.</p>
                <p>A <b>Bitstream, Unipessoal Lda</b> reserva-se o direito de monitorar todos os eventos e comentários e remover quaisquer eventos e comentários que possam ser considerados inadequados, ofensivos ou que causem violação destes Termos e Condições.</p>
                <p>Você garante e representa que:</p>
                <ul>
                    <li>Você tem o direito de postar os eventos e comentários em nosso website e tem todas as licenças e consentimentos necessários para fazê-lo;</li>
                    <li>Os eventos e comentários não invadem nenhum direito de propriedade intelectual, incluindo, sem limitação, direitos autorais, patente ou marca comercial de terceiros;</li>
                    <li>Os eventos e comentários não contêm nenhum material difamatório, calunioso, ofensivo, indecente ou de outra forma ilegal que seja uma invasão de privacidade;</li>
                    <li>Os eventos e comentários não serão usados ​​para solicitar ou promover negócios ou customizar ou apresentar atividades comerciais ou atividades ilegais. </li>
                </ul>
                <p>Você concede à <b>Bitstream, Unipessoal Lda</b> uma licença não exclusiva para usar, reproduzir, editar e autorizar terceiros a usar, reproduzir e editar qualquer um dos seus eventos e comentários em todas e quaisquer formas, formatos ou mídia.</p>

                <h2 className="subtitle has-text-primary mb-3">Hiperligações para o nosso conteúdo</h2>
                <p>As seguintes entidades podem se usar hiperligações para o nosso website sem aprovação prévia por escrito:</p>
                <ul>
                    <li>Agências governamentais;</li>
                    <li>Mecanismos de pesquisa;</li>
                    <li>Organizações de notícias;</li>
                    <li>Redes sociais e seus utilizadores;</li>
                    <li>Portais de Internet;</li>
                    <li>Diretórios online.</li>
                </ul>
                <p>Essas entidades podem ter links para nossa página inicial, publicações ou outras informações do website, desde que o link: (a) não seja enganoso de forma alguma; (b) não implique falsamente em patrocínio, endosso ou aprovação da parte vinculante e de seus produtos e / ou serviços; e (c) se encaixa no contexto do website da parte vinculante.</p>
                <p>É permitido o uso do logotipo da <b>Nutrit</b> para criar links para o nosso website sem aprovação prévia por escrito.</p>

                <p>Podemos considerar e aprovar outras solicitações de link de outros tipos de organizações quando solicitadas se decidirmos que: (a) o link não nos faria parecer desfavoravelmente para nós mesmos ou para nossos negócios credenciados; (b) a organização não possui registros negativos conosco; (c) o benefício para nós da visibilidade da hiperligação compensa a ausência de <b>Bitstream, Unipessoal Lda</b>; e (d) o link está no contexto de informações gerais de recursos. </p>
                <p>Se é uma das organizações interessadas em fazer um link para o nosso website, deverá informar-nos enviando um e-mail para <a href="mailto:info@bitstream.pt">info@bitstream.pt</a>. Por favor, inclua o seu nome, o nome da sua organização, informações de contato, bem como o URL do seu website, uma lista de quaisquer URLs a partir dos quais pretende criar um link para o nosso website e uma lista dos URLs em nosso website para os quais gostaria ter hiperligação. Espere 2 a 3 semanas por uma resposta.</p>
                
                <h2 className="subtitle has-text-primary mb-3">iFrames</h2>
                <p>Sem aprovação prévia e permissão por escrito, não pode criar iframes em torno de nossas páginas do nosso website.</p>

                <h2 className="subtitle has-text-primary mb-3">Responsabilidade de conteúdo</h2>
                <p>Não seremos responsáveis ​​por qualquer conteúdo que apareça em seu website. Você concorda em proteger-nos e defender-nos contra todas as reclamações que estão aumentando em seu website. Nenhum link deve aparecer em qualquer website que possa ser interpretado como difamatório, obsceno ou criminoso, ou que infrinja, de outra forma viole ou defenda a violação ou outra violação dos direitos de terceiros.</p>

                <h2 className="subtitle has-text-primary mb-3">Sua privacidade</h2>
                <p>Leia a <Link to = '/privacidade'>Política de Privacidade</Link>.</p>

                <h2 className="subtitle has-text-primary mb-3">Reserva de direitos</h2>
                <p>Nós nos reservamos o direito de solicitar que remova todos os links ou qualquer link específico para o nosso website. Você aprova a remoção imediata de todos os links para nosso website, mediante solicitação. Também nos reservamos o direito de amenizar estes termos e condições e sua política de vinculação a qualquer momento. Ao criar links para o nosso website, concorda em obedecer e cumprir os termos e condições de vinculação.</p>

                <h2 className="subtitle has-text-primary mb-3">Remoção de links de nosso website</h2>
                <p>Se encontrar qualquer link em nosso website que seja ofensivo por qualquer motivo, pode entrar em contato e informar-nos a qualquer momento. Consideraremos solicitações de remoção de links, mas não somos obrigados a fazê-lo ou responder-lhe diretamente.</p>

                <h2 className="subtitle has-text-primary mb-3">Correção da informação</h2>
                <p>Não garantimos que as informações neste website sejam corretas, não garantimos sua integridade ou precisão; nem prometemos garantir que o website permaneça disponível ou que o material no website seja mantido atualizado.</p>
                <p>Não garantimos que os conteúdos das receitas e fichas técnicas, incluindo o cálculo dos valores nutricionais, sejam corretos, não garantimos sua integridade ou precisão.</p>

                <h2 className="subtitle has-text-primary mb-3">Alteração de preços e funcionalidades</h2>
                <p>Nós nos reservamos o direito de, a qualquer momento e sem aviso prévio, adicionar, modificar ou remover funcionalidades.</p>
                <p>Nós nos reservamos o direito de alterar os preços dos serviços mediante aviso prévio de 30 dias.</p>
                <p>O utilizador não poderá responsabilizar-nos por quaisquer alterações de funcionalidades ou preços dos serviços.</p>

                <h2 className="subtitle has-text-primary mb-3">Exoneração de responsabilidade</h2>
                <p>Na extensão máxima permitida pela lei aplicável, excluímos todas as representações, garantias e condições relacionadas ao nosso website e ao uso deste website. Nada neste aviso irá:</p>
                <ul>
                    <li>limitar ou excluir a nossa responsabilidade ou a sua responsabilidade por morte ou danos pessoais;</li>
                    <li>limitar ou excluir nossa responsabilidade ou sua responsabilidade por fraude ou deturpação fraudulenta;</li>
                    <li>limitar qualquer uma de nossas responsabilidades de qualquer forma que não seja permitida pela lei aplicável; ou</li>
                    <li>excluir qualquer uma das nossas responsabilidades que não possam ser excluídas pela legislação aplicável.</li>
                </ul>
                <p>As limitações e proibições de responsabilidade definidas nesta Seção e em outras partes desta isenção de responsabilidade: (a) estão sujeitas ao parágrafo anterior; e (b) rege todas as responsabilidades decorrentes da isenção de responsabilidade, incluindo responsabilidades decorrentes de contratos, atos ilícitos e violação de dever estatutário.</p>
                <p>Desde que o website e as informações e serviços nele fornecidos sejam gratuitos, não seremos responsáveis ​​por perdas ou danos de qualquer natureza.</p>
            </div>
        </section>
    );
}

export default Terms;
